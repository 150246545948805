import React, { Component, MouseEventHandler } from 'react'
import { Table } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
/**
 * Declare CreateProject Props
 */
type ClientsTableProps = {
    clients: Client[],
    onRemove: (MouseEventHandler<HTMLButtonElement>),
    onLearnMore: (MouseEventHandler<HTMLButtonElement>),
    limit?: number,
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ClientsTable extends Component<ClientsTableProps> {


    render() {

        let clients = this.props.clients;

        if ( this.props.limit && this.props.limit > 0 ) {
            clients = clients?.splice(0, 5);
        }

        if ( clients ) {
            return(
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Created at</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{clients.map((client: Client) => (
                        <tr key={client.id}>
                            <td><span className='inliveSVG' dangerouslySetInnerHTML={{__html: client.client_logo}}></span></td>
                            <td><span className='projectName'>{client.client_name}</span></td>
                            <td>{client.date_created}</td>
                            <td className='actionTD'>
                                <button title="Learn more" onClick={this.props.onLearnMore} name={client.id} className="btn altButton moreButton"><FontAwesomeIcon icon={faEllipsisH} /></button>
                                <button title="Delete Project" onClick={this.props.onRemove} name={client.id} className="btn mainButton deleteButton"><FontAwesomeIcon icon={faCircleXmark} /></button>
                            </td>
                      </tr>
                    ))}</tbody>
                    </Table>
            );
        } else {
            return (
                <p>No clients found yet.</p>
            );
        }
        
    }
}

export default ClientsTable;