import React, { Component } from 'react'
import { Stepper } from '@mantine/core';
import Message from '../message';

/**
 * Declare CreateProject Props
 */
type ManageProjectFormProps = {
    project_status: string,
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ProjectStatus extends Component<ManageProjectFormProps> {

    projectStatus = () => {
        let status = 1;

        switch ( this.props.project_status ) {
            case 'Created':
                status = 1;
                break;
            case 'Pretranslated':
                status = 2;
                break;
            case 'Translations_In_Correction':
                status = 3;
                break;
            case 'Translations_Corrected':
                status = 4;
                break;
            case 'Completed':
                status = 5;
                break;
        }

        return status;
    }

    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        return(
            <Stepper active={this.projectStatus()} breakpoint="sm">
                <Stepper.Step label="Create Project" description="Upload WPML XLIFF files">
                </Stepper.Step>
                <Stepper.Step label="Pretranslate Project" description="Translate project with DeepL">
                </Stepper.Step>
                <Stepper.Step label="Export XLSX" description="Export project files for correction">
                </Stepper.Step>
                <Stepper.Step label="Import XLSX" description="Import corrected XLSX files">
                </Stepper.Step>
                <Stepper.Completed>
                    <Message content="Project translation complete. You can now download the XLIFF files for WPML." messageType="success" ></Message>
                </Stepper.Completed>
            </Stepper>
        );
    }
}

export default ProjectStatus