import React, { useEffect, useState } from 'react';
import './../../../public/css/instances.css'
import 'reactjs-popup/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector} from '../../../hooks';
import { initializeTranslationProjects } from '../../../reducers/translationReducer';
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router';
import { Grid, Modal } from '@mantine/core';
import ProjectsTable from '../../elements/translation/projects-table';
import CreateTranslationForm from '../../elements/translation/create-translation-form';
import { useDispatch } from 'react-redux';
import projectService from '../../../services/translationService';


/**
 * Type Definition for InstancePage properties
 */
type ProjectPageProps = {
    user: LoggedUser|null
}

/**
 * Instace Page
 * Render the pages that allowed the user to view and manage his inventory Projects
 * 
 * @returns the rendered HTML
 */
const ProjectPage = ( props: ProjectPageProps ) => {
    const projectDispatch = useAppDispatch()
    useEffect(() => {
        projectDispatch(initializeTranslationProjects(props.user)) 
    },[projectDispatch, props.user])

    let dispatch = useDispatch();
    let user: LoggedUser;

    const [openedModal, setOpenedModal] = useState(false);
    const navigate = useNavigate();


    // @ts-ignore
    const projects: Array<InventryProject> = useAppSelector<{ translationProjects: Array<translationProject>}>(({translationProjects}) => {
        return translationProjects;
    })

    // @ts-ignore
    const clients: Array<Client> = useAppSelector<{ clients: Array<Client>}>(({clients}) => {
        return clients;
    })

    if ( ! props.user ) {
        return (<></>);
    } else {
        user = props.user;
    }

    const onRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;

        if ( window.confirm( "Are you sure you wish to delete this project? This cannot be undone." ) !== true ) {
            return;
        }

        projectService.deleteProject(user, button.name).then( ( res ) => {

            if ( res && res.message ) {
                alert( "Could not delete project: " + res.message );
            } else {
                dispatch({
                    type: "DELETE_PROJECT",
                    data: button.name,
                })
            }
        }).catch((exception) => {
            console.log( exception );
            alert( "Could not delete project. Please check your console for more info" );
        });
    }

    const onLearnMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        
        navigate( "/translation/" + button.name  ); 
    }

    if (!props.user) {
        return (
            <Navigate to={{
                pathname: '/login'
              }} />
        )
    }

    return(
        <div className="project-wrapper">
            <Modal
                opened={openedModal}
                onClose={() => setOpenedModal(false)}
                title="Adding new project..."
            >
                <CreateTranslationForm clients={clients} onCreate={(project: TranslationProject) => { 
                        dispatch({ 
                            type: 'ADD_PROJECT',
                            data: project
                        }) 
                    }}
                    user={props.user} 
                />
            </Modal>
            <Grid justify="space-between" align="center">
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 400 }}><h1>Translation Projects</h1></Grid.Col>
                <Grid.Col span={2} style={{ minHeight: 80 }}>
                    <button className="addNewButton mainButton" onClick={() => setOpenedModal(true)}><FontAwesomeIcon icon={faPlus} ></FontAwesomeIcon> Add Project</button>
                </Grid.Col>
            </Grid>
            <ProjectsTable projects={projects} onRemove={onRemove} onLearnMore={onLearnMore} />
        </div>
    )
}

export default ProjectPage;