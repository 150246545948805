import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react'
import jobService from '../../services/jobService';
import projectService from '../../services/translationService';
import Message from '../elements/message';
import './../../public/css/instances.css';


/**
 * Type definition for AddInstance Class properties
 */
type TranslateProjectProps = {
	user: LoggedUser,
	project: TranslationProject,
	onUpdate: (project: TranslationProject) => void,
}

type TranslateProjectState = {
	status: Array<{ type: string, content: string }>
	message: {
		type: string,
		content: string,
	} | null
}

/**
 * AddInstance component
 * Used to turn a valid license into an instance
 */
class TranslateProject extends Component<TranslateProjectProps, TranslateProjectState> {

	/**
	 * Construct the TranslateProject Class
	 * Sets the state of the instance.
	 * 
	 * @param props the login properties as define above
	 */
	 constructor(props: TranslateProjectProps) {
		super(props)
		
		this.state = {
			status: [],
			message: null,
		}
	}

	translationClickHandler = () => {
		this.setState({
			message: {
				type: "info",
				content: "Translation in progress. Please do not close this window.",
			}
		});

		let elem = this;
		let index = 0;

		this.props.project.jobs?.forEach((job) => {
			
			index++;

			jobService.translateJob( this.props.user, job ).then((response) => {

				let status = elem.state.status;

				if ( response && response.id ) {
					// Success
					status.push({ type: "success", content: "Successfully translated " + job.file_name });

				} else if ( response && response.message ) {
					console.log( response );
					status.push({ type: "warning", content: "Errors encountered while translating " + job.file_name + ": " + response.message });
				} else {
					console.log( response );
					status.push({ type: "warning", content: "Failed to translate " + job.file_name + ". Please check your console." });
				}

				elem.setState({
					status: status
				});

				if ( index === elem.props.project.jobs?.length ) {
					projectService.updateProject( elem.props.user, {
						project_id: elem.props.project.id,
						project_status: "Pretranslated",
					} ).then(( res ) => {
	
						if ( res && res.id ) {
							this.setState({
								message: {
									type: 'success',
									content: 'Translation complete.'
								}
							});

							// success
							elem.props.onUpdate( res );
						} else {
							console.log( res );
							elem.setState({
								message: {
									type: "warning",
									content: "Failed to update project status: " + res.message ,
								}
							});
						}
						
					});
				}

			}).catch((error) => {
				console.log( error );
				
				let status = elem.state.status;
				status.push({ type: "warning", content: "Failed to translate " + job.file_name + ". Please check your console" });
				
				elem.setState({
					status: status
				});
			});

		});

		

	}

	/**
	 * Render the Component
	 * 
	 * @returns The HTML for the rendered component
	 */
	render() {

		let statusIndicator = <button className="mainButton btn btn-primary" onClick={this.translationClickHandler}><FontAwesomeIcon icon={faLanguage} />Begin Translation</button>

		//  Determine if we should render a message
        if ( this.state.message ) {
            statusIndicator = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

		// let the user choose which license to create the instance for
		return (
			<div className="addInstance chooseLicense wrapper">
				{statusIndicator}
				<div className='seperator25 whitespace'></div>
				{this.state.status.map(message => 
                        <Message
							key={message.content}
                            messageType={message.type}
							content={message.content}
                        />    
                    )}
			</div>
		)
		
	}
}

export default TranslateProject
