import { Table } from '@mantine/core'
import React, { Component } from 'react'

/**
 * Declare CreateProject Props
 */
type LocationsProps = {
    project: RebrandingProject
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class Locations extends Component<LocationsProps> {

    locationOutput = ( color: Color ) => {
        return (
        <div className="color-wrapper">
            <div className="color-spot" style={{ background: color.hex }}></div>
            <div className="color-info-wrapper">
                <table className="color-table">
                    <tr>
                        <th>cmyk</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>rgb</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>hex</th>
                        <td>{color.hex}</td>
                    </tr>
                </table>
            </div>
        </div>
        )
    }

    render() {

        return(
            <div className="Locations-wrapper">
                {this.props.project.locations.map( (location, index) => {
                    return(
                        <>
                            <h1>Location {index}</h1>
                            <h3>Address</h3>
                            <Table className='detailsTable locationTable'>
                                <tr>
                                    <th>Street</th>
                                    <td>{location.address.street}</td>
                                </tr>
                                <tr>
                                    <th>Zipcode</th>
                                    <td>{location.address.zipcode}</td>
                                </tr>
                                <tr>
                                    <th>City</th>
                                    <td>{location.address.city}</td>
                                </tr>
                                <tr>
                                    <th>Country</th>
                                    <td>{location.address.country}</td>
                                </tr>
                            </Table>
                            <h3>Banking</h3>
                            <Table className='detailsTable locationTable'>
                                <tr>
                                    <th>Bank</th>
                                    <td>{location.banking.bank}</td>
                                </tr>
                                <tr>
                                    <th>IBAN</th>
                                    <td>{location.banking.iban}</td>
                                </tr>
                                <tr>
                                    <th>BIC</th>
                                    <td>{location.banking.bic}</td>
                                </tr>
                            </Table>
                            <h3>Legal Info</h3>
                            <Table className='detailsTable locationTable'>
                                <tr>
                                    <th>Management</th>
                                    <td>{location.legal_info.management}</td>
                                </tr>
                                <tr>
                                    <th>Commercial Register</th>
                                    <td>{location.legal_info.commercial_registration}</td>
                                </tr>
                                <tr>
                                    <th>VAT ID</th>
                                    <td>{location.legal_info.vat_id}</td>
                                </tr>
                            </Table>
                        </>
                    )
                })}
            </div>
        )
    }
}

export default Locations