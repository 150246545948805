import React, { useEffect, useState } from 'react';
import './../../../public/css/instances.css'
import 'reactjs-popup/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector} from '../../../hooks';
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router';
import { Grid, Modal } from '@mantine/core';
import { useDispatch } from 'react-redux';
import ClientsTable from '../../elements/clients/clients-table';
import clientService from '../../../services/clientService';
import { initializeClients } from '../../../reducers/clientsReducer';
import CreateClientForm from '../../elements/clients/create-client-form';


/**
 * Type Definition for InstancePage properties
 */
type ClientsPageProps = {
    user: LoggedUser|null
}

/**
 * Instace Page
 * Render the pages that allowed the user to view and manage his inventory Projects
 * 
 * @returns the rendered HTML
 */
const ClientsPage = ( props: ClientsPageProps ) => {
    const clientsDispatch = useAppDispatch()
    useEffect(() => {
        clientsDispatch(initializeClients(props.user)) 
    },[clientsDispatch, props.user])

    let dispatch = useDispatch();
    let user: LoggedUser;

    const [openedModal, setOpenedModal] = useState(false);
    const navigate = useNavigate();


    // @ts-ignore
    const clients: Array<Client> = useAppSelector<{ projects: Array<Client>}>(({clients}) => {
        return clients
    })
    
    if ( ! props.user ) {
        return( <></> );
    } else {
        user = props.user
    }

    const onRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;

        if ( window.confirm( "Are you sure you want to delete this client? This will also delete all rebranding and translation projects associated with this client." ) !== true ) {
            return;
        }

        clientService.deleteClient(user, button.name).then( ( res ) => {

            if ( res && res.message ) {
                alert( "Could not delete client: " + res.message );
            } else {
                dispatch({
                    type: "DELETE_CLIENT",
                    data: button.name,
                })
            }
        }).catch((exception) => {
            console.log( exception );
            alert( "Could not delete client. Please check your console for more info" );
        });
    }

    const onLearnMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        
        navigate( "/clients/" + button.name  ); 
    }

    if (!props.user) {
        return (
            <Navigate to={{
                pathname: '/login'
              }} />
        )
    }

    return(
        <div className="project-wrapper">
            <Modal
                opened={openedModal}
                onClose={() => setOpenedModal(false)}
                title="Create a new client"
            >
                <CreateClientForm onCreate={(client: Client) => { 
                        dispatch({ 
                            type: 'ADD_CLIENT',
                            data: client
                        }) 
                    }}
                    user={props.user} 
                />
            </Modal>
            <Grid justify="space-between" align="center">
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 400 }}><h1>Clients</h1></Grid.Col>
                <Grid.Col span={2} style={{ minHeight: 80 }}>
                    <button className="addNewButton mainButton" onClick={() => setOpenedModal(true)}><FontAwesomeIcon icon={faPlus} ></FontAwesomeIcon> Add Client</button>
                </Grid.Col>
            </Grid>
            <ClientsTable clients={clients} onRemove={onRemove} onLearnMore={onLearnMore} />
        </div>
    )
}

export default ClientsPage;