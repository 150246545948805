import { useState } from 'react';
import { Stepper, Button, Group, TextInput, Code, Select, Text, Box, ColorInput, NumberInput, ActionIcon, Accordion } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconTrash } from '@tabler/icons';
import rebrandingService from '../../../services/rebrandingService';
import { useDispatch } from 'react-redux';
import Message from '../message';

type MultiformProps = {
    clients: Array<Client>,
    user: LoggedUser,
    project?: RebrandingProject,
}

function CreateRebrandingMultiform(props: MultiformProps) {

    // Store & Set currently active tab
    const [active, setActive] = useState(0);
    const [message, setMessage] = useState( {
        type: '',
        message: '',
    } );

    let dispatch = useDispatch();
    let initialValues: RebrandingProject = {
        id: '',
        date_created: '',
        author: null,
        legal_name: '',
        display_name: '',
        client: {
            id: '',
            client_logo: '',
            client_name: '',
            date_created: '',
            author: null,
        },
        color_scheme: {
            prio1_colors: [
                {
                    hex: '',
                    cymk: {
                        'c': 0,
                        'y': 0,
                        'm': 0,
                        'k': 0,
                    },
                    rgb: {
                        'r': 0,
                        'g': 0,
                        'b': 0,
                    },
                },
            ],
            prio2_colors: [
                {
                    hex: '',
                    cymk: {
                        'c': 0,
                        'y': 0,
                        'm': 0,
                        'k': 0,
                    },
                    rgb: {
                        'r': 0,
                        'g': 0,
                        'b': 0,
                    },
                },
            ],
            greyscale_colors: [
                {
                    hex: '',
                    cymk: {
                        'c': 0,
                        'y': 0,
                        'm': 0,
                        'k': 0,
                    },
                    rgb: {
                        'r': 0,
                        'g': 0,
                        'b': 0,
                    },
                },
            ],
            highlight_color: {
                hex: '',
                cymk: {
                    'c': 0,
                    'y': 0,
                    'm': 0,
                    'k': 0,
                },
                rgb: {
                    'r': 0,
                    'g': 0,
                    'b': 0,
                },
            },
        },
        font_scheme: {
            headline: '',
            body: '',
        },
        locations: [
            {
                address: {
                    street: '',
                    zipcode: '',
                    city: '',
                    country: '',
                },
                legal_info: {
                    vat_id: '',
                    management: '',
                    commercial_registration: '',
                },
                banking: {
                    bank: '',
                    iban: '',
                    bic: '',
                },
                contact_details: {
                    phone: '',
                    email: '',
                    fax: '',
                }
            }]
        };

    if ( props.project ) {
        initialValues = props.project;
    }

    // Setup form & validation
    const form = useForm({
        initialValues: initialValues,
            
        validate: (values) => {
          if (active === 0) {
            return {
                legal_name:
                    values.legal_name.trim().length < 6
                        ? 'The legal name must have at least 6 letters and contain the legal entity of the company'
                        : null,
                display_name:
                    values.display_name.length < 3 ? 'The display name must have at least 3 letters.' : null,
                /*
                font_scheme: {
                    headline: (value: string) => (value.length < 2 ? 'Name should have at least 2 letters' : null),
                    body: (value: string) => (value.length < 2 ? 'Name should have at least 2 letters' : null),
                }
                */
            };
          }

          if (active === 1) {
            return {
                // color_scheme: {
                    // prio1_colors: (value: string | any[]) => (value.length > 3 ? 'At least three Prio 1 colors are required' : null),
                    // prio2_colors: (value: string | any[]) => (value.length > 3 ? 'At least three Prio 2 colors are required' : null),
                    // greyscale_colors: (value: string | any[]) => (value.length > 3 ? 'At least three greyscale colors are required' : null),
                    // highlight_color: (value: string | any[]) => (value == null ? 'The highlight color is required' : null),
                // }
            };
          }
    
          return {};
        },
    });


    // Arrange clients for use in select element
    const clientData = props.clients.map((client) => {
        return { value : client.id, label : client.client_name }
    });


    // Trigger next tab
    const nextStep = () =>
        setActive((current) => {
        if (form.validate().hasErrors) {
            console.log( 'ERRORS!' );
            console.log(form.errors);
            return current;
        }
        return current < 6 ? current + 1 : current;
        });

    // Trigger previous tab
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    // Create project
    const createProject = () => {
        if (form.validate().hasErrors) {
            alert( 'Cannot create project: There are still errors!' );
        } else {
            rebrandingService.createProject( props.user, form.values ).then((response) => {
                
                console.log( response );

                if ( response && response.id ) {
                    
                    setMessage({
                        type: 'success',
                        message: 'Rebranding Project successfully created!',
                    });

                    dispatch({ 
                        type: 'ADD_REBRANDING',
                        data: response
                    }) 
                } else if ( response && response.message ) {
                    setMessage({
                        type: 'warning',
                        message: response.message,
                    })
                } else {
                    setMessage({
                        type: 'warning',
                        message: "An unknown error occured",
                    })
                }
    
            }).catch((error) => {
                setMessage({
                    type: 'warning',
                    message: "An unknown error occured",
                })
            })
        }
    }

    const updateProject = () => {
        if ( form.validate().hasErrors) {
            alert( "Cannot update project. The form has still errors!" );
        } else {
            rebrandingService.updateProject(props.user, form.values ).then(( response ) => {

                if ( response && response.id ) {

                    if ( response && response.id ) {
                    
                        setMessage({
                            type: 'success',
                            message: 'Rebranding Project successfully updated!',
                        });
    
                        dispatch({ 
                            type: 'UPDATE_REBRANDING',
                            data: response
                        }) 
                    } else if ( response && response.message ) {
                        setMessage({
                            type: 'warning',
                            message: response.message,
                        })
                    } else {
                        setMessage({
                            type: 'warning',
                            message: "An unknown error occured",
                        })
                    }
                }

            }).catch((error) => {
                alert( error );
            });
            // Update project.
        }
    }

    const prio1Fields = form.values.color_scheme.prio1_colors.map((item, index) => (
        <Accordion.Item key={index} value={index > 0 ? index.toString() : '0'}>
            <Accordion.Control>
                <Group>
                    <ActionIcon color="red" onClick={() => form.removeListItem(`color_scheme.prio1_colors`, index)}>
                        <IconTrash size={16} />
                    </ActionIcon>
                    Color #{index > 0 ? index.toString() : '0'}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                <Box key={index}>
                    <div className='whitespace seperator25' ></div>
                    
                    <Text weight={500} size="sm" pr={90}>Hex</Text>
                    <Group key={index} mt="xs">
                        <ColorInput placeholder="#fff" {...form.getInputProps(`color_scheme.prio1_colors.${index}.hex`)} />
                    </Group>
                    <div className='whitespace seperator25' ></div>
                    <Text weight={500} size="sm" pr={90}>CYMK</Text>
                    <Group grow>
                        <NumberInput placeholder='100' max={100} min={0} description='C' {...form.getInputProps(`color_scheme.prio1_colors.${index}.cymk.c`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='Y' {...form.getInputProps(`color_scheme.prio1_colors.${index}.cymk.y`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='M' {...form.getInputProps(`color_scheme.prio1_colors.${index}.cymk.m`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='K' {...form.getInputProps(`color_scheme.prio1_colors.${index}.cymk.k`)}/>
                    </Group>
                    <div className='whitespace seperator25' ></div>
                    <Text weight={500} size="sm" pr={90}>RGB</Text>
                    <Group grow>
                        <NumberInput placeholder='255' max={255} min={0} description='R' {...form.getInputProps(`color_scheme.prio1_colors.${index}.rgb.r`)}/>
                        <NumberInput placeholder='255' max={255} min={0} description='G' {...form.getInputProps(`color_scheme.prio1_colors.${index}.rgb.g`)}/>
                        <NumberInput placeholder='255' max={255} min={0} description='B' {...form.getInputProps(`color_scheme.prio1_colors.${index}.rgb.b`)}/>
                    </Group>
                    <div className='whitespace seperator25' ></div>
                </Box>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const prio2Fields = form.values.color_scheme.prio2_colors.map((item, index) => (
        <Accordion.Item key={index} value={index > 0 ? index.toString() : '0'}>
            <Accordion.Control>
                <Group>
                    <ActionIcon color="red" onClick={() => form.removeListItem(`color_scheme.prio2_colors`, index)}>
                        <IconTrash size={16} />
                    </ActionIcon>
                    Color #{index > 0 ? index.toString() : '0'}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                <Box key={index}>
                    <Text weight={500} size="sm" pr={90}>Hex</Text>
                    <Group key={index} mt="xs">
                        <ColorInput placeholder="#fff" {...form.getInputProps(`color_scheme.prio2_colors.${index}.hex`)} />
                    </Group>
                    <div className='whitespace seperator25' ></div>
                    <Text weight={500} size="sm" pr={90}>CYMK</Text>
                    <Group grow>
                        <NumberInput placeholder='100' max={100} min={0} description='C' {...form.getInputProps(`color_scheme.prio2_colors.${index}.cymk.c`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='Y' {...form.getInputProps(`color_scheme.prio2_colors.${index}.cymk.y`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='M' {...form.getInputProps(`color_scheme.prio2_colors.${index}.cymk.m`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='K' {...form.getInputProps(`color_scheme.prio2_colors.${index}.cymk.k`)}/>
                    </Group>
                    <div className='whitespace seperator25' ></div>
                    <Text weight={500} size="sm" pr={90}>RGB</Text>
                    <Group grow>
                        <NumberInput placeholder='255' max={255} min={0} description='R' {...form.getInputProps(`color_scheme.prio2_colors.${index}.rgb.r`)}/>
                        <NumberInput placeholder='255' max={255} min={0} description='G' {...form.getInputProps(`color_scheme.prio2_colors.${index}.rgb.g`)}/>
                        <NumberInput placeholder='255' max={255} min={0} description='B' {...form.getInputProps(`color_scheme.prio2_colors.${index}.rgb.b`)}/>
                    </Group>
                    <div className='whitespace seperator25' ></div>
                </Box>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const corporateFields = form.values.color_scheme.greyscale_colors.map((item, index) => (
        <Accordion.Item key={index} value={index > 0 ? index.toString() : '0'}>
            <Accordion.Control>
                <Group>
                    <ActionIcon color="red" onClick={() => form.removeListItem(`color_scheme.greyscale_colors`, index)}>
                        <IconTrash size={16} />
                    </ActionIcon>
                    Color #{index > 0 ? index.toString() : '0'}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                <Box key={index}>
                    <Text weight={500} size="sm" pr={90}>Hex</Text>
                    <Group key={index} mt="xs">
                        <ColorInput placeholder="#fff" {...form.getInputProps(`color_scheme.greyscale_colors.${index}.hex`)} />
                    </Group>
                    <div className='whitespace seperator25' ></div>
                    <Text weight={500} size="sm" pr={90}>CYMK</Text>
                    <Group grow>
                        <NumberInput placeholder='100' max={100} min={0} description='C' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.cymk.c`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='Y' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.cymk.y`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='M' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.cymk.m`)}/>
                        <NumberInput placeholder='100' max={100} min={0} description='K' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.cymk.k`)}/>
                    </Group>
                    <div className='whitespace seperator25' ></div>
                    <Text weight={500} size="sm" pr={90}>RGB</Text>
                    <Group grow>
                        <NumberInput placeholder='255' max={255} min={0} description='R' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.rgb.r`)}/>
                        <NumberInput placeholder='255' max={255} min={0} description='G' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.rgb.g`)}/>
                        <NumberInput placeholder='255' max={255} min={0} description='B' {...form.getInputProps(`color_scheme.greyscale_colors.${index}.rgb.b`)}/>
                    </Group>
                    <div className='whitespace seperator25' ></div>
                </Box>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const locationFields = form.values.locations.map((item, index) => (
        <Accordion.Item key={index} value={index > 0 ? index.toString() : '0'}>
            <Accordion.Control>
                <Group>
                    <ActionIcon color="red" onClick={() => form.removeListItem(`locations`, index)}>
                        <IconTrash size={16} />
                    </ActionIcon>
                    Location #{index > 0 ? index.toString() : '0'}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>
                <h3 className='infoSubHeader'>Address</h3>
                <Group grow>
                    <TextInput label="Street" placeholder="Musterstraße 12" {...form.getInputProps(`locations.${index}.address.street`)} />
                    <TextInput label="Zipcode" placeholder="12345" {...form.getInputProps(`locations.${index}.address.zipcode`)} />
                    <TextInput label="City" placeholder="Musterstadt" {...form.getInputProps(`locations.${index}.address.city`)} />
                    <TextInput label="Country" placeholder="Germany" {...form.getInputProps(`locations.${index}.address.country`)} />
                </Group>
                <h3 className='infoSubHeader'>Legal Info</h3>
                <Group grow>
                    <TextInput label="VAT ID" placeholder="DE123456789" {...form.getInputProps(`locations.${index}.legal_info.vat_id`)} />
                    <TextInput label="Commercial Register" placeholder="AG Darmstadt, HRB12345" {...form.getInputProps(`locations.${index}.legal_info.commercial_registration`)} />
                    <TextInput label="Managing Director(s)" placeholder="Max Muster" {...form.getInputProps(`locations.${index}.legal_info.management`)} />
                </Group>
                <h3 className='infoSubHeader'>Bank details</h3>
                <Group grow>
                    <TextInput label="Bank" placeholder="Deutsche Bank" {...form.getInputProps(`locations.${index}.banking.bank`)} />
                    <TextInput label="IBAN" placeholder="DE12 1234 4567 7890" {...form.getInputProps(`locations.${index}.banking.iban`)} />
                    <TextInput label="BIC" placeholder="HELADEF1DIE" {...form.getInputProps(`locations.${index}.banking.bic`)} />
                </Group>
                <h3 className='infoSubHeader'>Contact information</h3>
                <Group grow>
                    <TextInput label="Phone" placeholder="+49 123 456 - 7" {...form.getInputProps(`locations.${index}.contact_details.phone`)} />
                    <TextInput label="Fax" placeholder="+49 123 456 - 8" {...form.getInputProps(`locations.${index}.contact_details.fax`)} />
                    <TextInput label="Email" placeholder="info@company.com" {...form.getInputProps(`locations.${index}.contact_details.email`)} />
                </Group>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const messageElement = () => {
        if ( message.type ) {
            return <Message messageType={message.type} content={message.message} />
        } else {
            return <></>
        }
    }
    

    return (
        <>
            {messageElement()}
            <div className='whitespace separator25'></div>
            <Stepper active={active} breakpoint="sm">
                <Stepper.Step label="General" description="Company Data">
                    <TextInput label="Company Display Name" placeholder="Musterfirma" {...form.getInputProps('display_name')} />
                    <TextInput label="Full Legal Name" placeholder="Musterfirma GmbH & Co KG" {...form.getInputProps('legal_name')} />
                    <Select label="Client" placeholder='Please choose' data={clientData} {...form.getInputProps('client.id')}></Select>
                    <Group grow>
                        <Select label='Headline Font' placeholder='Please choose' data={[{ value: 'abc', label: 'ABC' }]} {...form.getInputProps('font_scheme.headline')} />
                        <Select label='Body Font' placeholder='Please choose' data={[{ value: 'abc', label: 'ABC' }]} {...form.getInputProps('font_scheme.body')} />
                    </Group>
                </Stepper.Step>
                <Stepper.Step label="Locations" description="Company Data">
                    <h2 className='colorSchemeHeadline'>Locations</h2>
                    <Accordion variant="separated">
                        {locationFields}
                    </Accordion>
                    <div className='seperator25 whitespace'></div>
                    <Button
                        size='xs' className='addColorButton' onClick={
                            () => { 
                                form.insertListItem('locations', {
                                    address: {
                                        street: '',
                                        zipcode: '',
                                        city: '',
                                        country: '',
                                    },
                                    legal_info: {
                                        vat_id: '',
                                        management: '',
                                        commercial_registration: '',
                                    },
                                    banking: {
                                        bank: '',
                                        iban: '',
                                        bic: '',
                                    },
                                    contact_details: {
                                        phone: '',
                                        email: '',
                                        fax: '',
                                    }
                                } )
                            }}>
                    Add Location
                    </Button>
                </Stepper.Step>
                <Stepper.Step label="Priority 1" description="Color Scheme">
                    <h2 className='colorSchemeHeadline'>Priority 1 Colors</h2>
                    <Accordion variant="separated">
                        {prio1Fields}
                    </Accordion>
                    <div className='seperator25 whitespace'></div>
                    <Button
                        size='xs' className='addColorButton' onClick={
                            () => { 
                                form.insertListItem('color_scheme.prio1_colors', { hex: '', cymk: { 'c': 0, 'y': 0, 'm': 0, 'k': 0, }, rgb: {'r': 0, 'g': 0,'b': 0,},}, )
                            }}>
                    Add Color
                    </Button>
                </Stepper.Step>
                <Stepper.Step label="Priority 2" description="Color Scheme">
                    <h2 className='colorSchemeHeadline'>Priority 2 Colors</h2>
                    <Accordion variant="separated">
                        {prio2Fields}
                    </Accordion>
                    <div className='seperator25 whitespace'></div>
                    <Button
                        size='xs' className='addColorButton' onClick={
                            () => { 
                                form.insertListItem('color_scheme.prio2_colors', { hex: '', cymk: { 'c': 0, 'y': 0, 'm': 0, 'k': 0, }, rgb: {'r': 0, 'g': 0,'b': 0,},}, )
                            }}>
                    Add Color
                    </Button>
                </Stepper.Step>
                <Stepper.Step label="Highlight" description="Color Scheme">
                    <h2 className='colorSchemeHeadline'>Highlight Color</h2>
                    <Box>
                        <div className='whitespace seperator25' ></div>
                        <Text weight={500} size="sm" pr={90}>Hex</Text>
                        <Group mt="xs">
                            <ColorInput placeholder="#fff" {...form.getInputProps(`color_scheme.highlight_color.hex`)} />
                        </Group>
                        <div className='whitespace seperator25' ></div>
                        <Text weight={500} size="sm" pr={90}>CYMK</Text>
                        <Group grow>
                            <NumberInput placeholder='100' max={100} min={0} description='C' {...form.getInputProps(`color_scheme.highlight_color.cymk.c`)}/>
                            <NumberInput placeholder='100' max={100} min={0} description='Y' {...form.getInputProps(`color_scheme.highlight_color.cymk.y`)}/>
                            <NumberInput placeholder='100' max={100} min={0} description='M' {...form.getInputProps(`color_scheme.highlight_color.cymk.m`)}/>
                            <NumberInput placeholder='100' max={100} min={0} description='K' {...form.getInputProps(`color_scheme.highlight_color.cymk.k`)}/>
                        </Group>
                        <div className='whitespace seperator25' ></div>
                        <Text weight={500} size="sm" pr={90}>RGB</Text>
                        <Group grow>
                            <NumberInput placeholder='255' max={255} min={0} description='R' {...form.getInputProps(`color_scheme.greyscale_colors.rgb.r`)}/>
                            <NumberInput placeholder='255' max={255} min={0} description='G' {...form.getInputProps(`color_scheme.greyscale_colors.rgb.g`)}/>
                            <NumberInput placeholder='255' max={255} min={0} description='B' {...form.getInputProps(`color_scheme.greyscale_colors.rgb.b`)}/>
                        </Group>
                        <div className='whitespace seperator25' ></div>
                    </Box>
                </Stepper.Step>
                <Stepper.Step label="Corporate" description="Color Scheme">
                    <h2 className='colorSchemeHeadline'>Corporate / Greyscale Colors</h2>
                    <Accordion variant="separated">
                        {corporateFields}
                    </Accordion>
                    <div className='seperator25 whitespace'></div>
                    <Button
                        size='xs' className='addColorButton' onClick={
                            () => { 
                                form.insertListItem('color_scheme.greyscale_colors', { hex: '', cymk: { 'c': 0, 'y': 0, 'm': 0, 'k': 0, }, rgb: {'r': 0, 'g': 0,'b': 0,},}, )
                            }}>
                    Add Color
                    </Button>
                </Stepper.Step>
                <Stepper.Completed>
                Success! You can now close this window.
                <Code block mt="xl">
                    {JSON.stringify(form.values, null, 2)}
                </Code>
                </Stepper.Completed>
            </Stepper>
            <Group position="right" mt="xl">
                {active !== 0 && active < 6 && (
                <Button variant="default" onClick={prevStep}>
                    Back
                </Button>
                )}
                {active <5 && <Button onClick={nextStep}>Next step</Button>}
                {active ===5 && ! props.project && <Button onClick={createProject}>Create Project</Button>}
                {active ===5 && props.project && <Button onClick={updateProject}>Update Project</Button>}
            </Group>
        </>
    )
}

export default CreateRebrandingMultiform;