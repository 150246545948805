import React, { Component, MouseEventHandler } from 'react'
import { Table } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
/**
 * Declare CreateProject Props
 */
type ProjectsTableProps = {
    projects: RebrandingProject[],
    onRemove: (MouseEventHandler<HTMLButtonElement>),
    onLearnMore: (MouseEventHandler<HTMLButtonElement>),
    limit?: number,
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class RebrandingProjectsTable extends Component<ProjectsTableProps> {


    render() {

        let projects = this.props.projects;

        if ( this.props.limit && this.props.limit > 0 ) {
            projects = projects?.splice(0, 5);
        }

        if ( projects ) {
            return(
                <Table striped>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Name</th>
                            <th>Created at</th>
                            <th>Locations</th>
                        </tr>
                    </thead>
                    <tbody>{projects.map((project: RebrandingProject) => (
                        <tr key={project.id}>
                            <td>{project.client?.client_name}</td>
                            <td><span className='projectName'>{project.display_name}</span></td>
                            <td>{project.date_created}</td>
                            <td>{project.locations.length}</td>
                            <td className='actionTD'>
                                <button title="Learn more" onClick={this.props.onLearnMore} name={project.id} className="btn altButton moreButton"><FontAwesomeIcon icon={faEllipsisH} /></button>
                                <button title="Delete Project" onClick={this.props.onRemove} name={project.id} className="btn mainButton deleteButton"><FontAwesomeIcon icon={faCircleXmark} /></button>
                            </td>
                      </tr>
                    ))}</tbody>
                    </Table>
            );
        } else {
            return (
                <p>No projects found yet.</p>
            );
        }
        
    }
}

export default RebrandingProjectsTable