import React, { Component } from 'react'
import { Table } from '@mantine/core';

/**
 * Declare CreateProject Props
 */
type ClientDetailsProps = {
    client: Client
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ClientDetails extends Component<ClientDetailsProps> {

    render() {

        return(
            <div className="ClientDetails-wrapper">
                <div className='seperator25 whitespace'></div>
                <Table className='detailsTable'>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>{this.props.client.client_name}</td>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <td>{this.props.client.id}</td>
                        </tr>
                        <tr>
                            <th>Date created</th>
                            <td>{this.props.client.date_created}</td>
                        </tr>
                        <tr>
                            <th>Author</th>
                            <td>{this.props.client.author?.first_name} {this.props.client.author?.last_name}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default ClientDetails