import { Navigate } from 'react-router';
import AccountForm from '../elements/account-form';

/** 
 * Type Definition for Account Page Props
 */
type AccountPageProps = {
    user: LoggedUser|null
}

/**
 * 
 * @param param0 Account Page Props
 * @returns 
 */
const AccountPage = ( props: AccountPageProps ) => {

    if (!props.user) {
        return (
            <Navigate to={{
                pathname: '/login'
              }} />
        )
      }

    return (
        <div className="accountWrapper">
            <h1>Account</h1>
            <AccountForm user={props.user}/>
        </div>
    )
}

export default AccountPage;