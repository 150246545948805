import axios from 'axios'
import config from '../config';

/**
 * Retrieve detailed user information to store in session
 * 
 * @param {object} user 
 * @returns detailed user information
 */
const getUserDetail = async (user: LoggedUser) => {

	if ( ! user ) {
		return null;
	}

	const request =  axios({
        "method": "GET",
        "url": config.api.url + "/api/user/" + user.user_object.username,
        "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
        }
    })

    const response = await request;
    return response.data;
}


/**
 * Update user data
 * 
 * @param {object} user
 * @returns The udpated user
 */
const updateUser = async (user: LoggedUser, data: UserUpdatePayload ) => {

	if ( ! user ) {
		return null;
	}

	return axios({
        "method": "PUT",
        "url": config.api.url + "/api/user/" + user.user_object.username,
        "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
        },
        "data": data
    })
}

const getUsers = async (user: LoggedUser) => {

    if ( ! user || user.user_object.role !== 'admin' ) {
        return null;
    }

    try {
        const request = axios({
          "method": "GET",
          "url": config.api.url + "/api/user",
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const createUser = async (user: LoggedUser, userData: {
    username: string,
    email: string,
    first_name: string,
    last_name: string,
    role: string,
    password: string,
}) => {

    if ( ! user || user.user_object.role !== 'admin' || ! userData) {
        return null;
    }

    try {
        const request = axios({
          "method": "POST",
          "url": config.api.url + "/api/user",
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
          "data": userData
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const deleteUser = async (user: LoggedUser, username: string) => {
    if ( ! user || user.user_object.role !== 'admin' || ! username) {
        return null;
    }

    try {
        const request = axios({
          "method": "DELETE",
          "url": config.api.url + "/api/user/" + username,
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const userService = {
  getUsers,
  createUser,
  getUserDetail,
  updateUser,
  deleteUser,
}

export default userService;