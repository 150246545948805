import React, { useEffect } from 'react';
import './../../public/css/instances.css'
import 'reactjs-popup/dist/index.css';
import { useAppDispatch, useAppSelector} from '../../hooks';
import { initializeTranslationProjects } from '../../reducers/translationReducer';
import { initializeRebrandingProjects } from '../../reducers/rebrandingReducer';
import DashboardStats from '../elements/dashboard/dashboard-stats';
import { CardGradient } from '../elements/dashboard/sections-link';
import { IconUsers, IconColorSwatch, IconLanguage } from '@tabler/icons';
import { SimpleGrid } from '@mantine/core';
import { Navigate } from 'react-router-dom';
import { initializeClients } from '../../reducers/clientsReducer';

/**
 * Type Definition for InstancePage properties
 */
type DashboardProps = {
    user: LoggedUser|null
}

/**
 * Instace Page
 * Render the pages that allowed the user to view and manage his inventory Projects
 * 
 * @returns the rendered HTML
 */
const Dashboard = ( props: DashboardProps ) => {

    const appDispatch = useAppDispatch();

    // Get clients
    useEffect(() => {
        appDispatch(initializeClients(props.user!))
    },[appDispatch, props.user])

    // Get translation projects
    useEffect(() => {
        appDispatch(initializeTranslationProjects(props.user!))
    },[appDispatch, props.user])

    // Get rebranding projects
    useEffect(() => {
        appDispatch(initializeRebrandingProjects(props.user!))
    },[appDispatch, props.user])

    // @ts-ignore
    const clients: Array<Client> = useAppSelector<{ clients: Array<Client>}>(({clients}) => {
        return clients;
    })

    // @ts-ignore
    const translationProjects: Array<TranslationProject> = useAppSelector<{ translationProjects: Array<TranslationProject>}>(({translationProjects}) => {
        return translationProjects;
    })

    // @ts-ignore
    const rebrandingProjects: Array<RebrandingProject> = useAppSelector<{ rebrandingProjects: Array<TranslationProject>}>(({rebrandingProjects}) => {
        return rebrandingProjects;
    })


    if ( ! props.user ) {
        return <Navigate to="/login" />
    }

    return(
        <div className="project-wrapper">
            <h1>Dashboard</h1>
            <DashboardStats translationProjects={translationProjects} rebrandingProjects={rebrandingProjects} clients={clients}  />
            <div className='seperator50 whitespace'></div>
            <h2>Quick actions</h2>
            <SimpleGrid cols={3} >
                <CardGradient title={'Clients'} description={'View and manage clients for which to create translation or rebranding projects'} target={'/clients'} icon={IconUsers} />
                <CardGradient title={'Translation Projects'} description={'Create or view translation projects for an existing client.'} target={'/translation'} icon={IconLanguage} />
                <CardGradient title={'Rebranding Projects'} description={'View and manage existing rebranding projects or add a new one.'} target={'/rebranding'} icon={IconColorSwatch} />
            </SimpleGrid>
            <div className='seperator50 whitespace'></div>
        </div>
    )
}

export default Dashboard;