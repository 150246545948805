import { combineReducers} from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import userReducer, { usersReducer } from './reducers/userReducer'
import projectReducer, { projectDetailReducer } from './reducers/translationReducer'
import jobReducer, { jobDetailReducer } from './reducers/jobReducer'
import clientsReducer from './reducers/clientsReducer'
import rebrandingReducer from './reducers/rebrandingReducer'

const reducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  clients: clientsReducer,
  rebrandingProjects: rebrandingReducer,
  translationProjects: projectReducer,
  translationProject: projectDetailReducer,
  jobs: jobReducer,
  job: jobDetailReducer,
})

const store = configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, usersLoggedUsersState}
export type AppDispatch = typeof store.dispatch

export default store