import React, { Component } from 'react'
import projectService from '../../../services/translationService'
import Message from '../message'
import Select from 'react-select';

/**
 * Declare CreateProject Props
 */
type CreateTranslationFormProps = {
    user: LoggedUser,
    onCreate: (project: TranslationProject) => void,
    clients: Client[],
}

/**
 * Declare AccountForm State
 */
type CreateTranslationFormState = {
    project_name: string,
    project_files: Blob | string,
    client_id: string,
    message: {
        type: string,
        content: string,
    } | null
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class CreateTranslationForm extends Component<CreateTranslationFormProps, CreateTranslationFormState> {


    /**
     * Constructor
     * Initiante the AccountForm Component Class
     * 
     * @param props The CreateTranslationFormProps
     */
    constructor(props: CreateTranslationFormProps) {
        super(props)

        this.state = {
            project_name: "",
            project_files: "",
            client_id: "",
            message: null
        }
    }

    

    
    /**
     * Handle user update.
     * This function is called when the user hits the submit button. 
     * Calls the backend API with the provided data and updates it
     * 
     * @param form The HTML Form Element
     */
    onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

        form.preventDefault()
           
        // define data to update
        let formData = new FormData();
        formData.append('project_name', this.state.project_name );
        formData.append('client_id', this.state.client_id );
        formData.append('project_files', this.state.project_files );

        let elem = this

        projectService.createProject(this.props.user, formData).then(function(res: any) {
            if ( res && res.id ) {
                elem.setState({
                    message: {
                        type: "success",
                        content: "Project successfully created"
                    }
                });

                elem.props.onCreate( res );
            } else if ( res && res.message ) {
                elem.setState({
                    message: {
                        type: "warning",
                        content: res.message
                    }
                });
                
            } else {

                elem.setState({
                    message: {
                        type: "warning",
                        content: "An unknown error occured."
                    }
                });
            }
        });
    }


    /**
     * Handle form input changes
     * Updates the corresponding values and store the in the class's state
     * 
     * @param element The HTML Input Element
     */
    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void  => {
        this.setState({ project_name: element.currentTarget.value });
    }

    handleSelectChange = (option: { value: string, label: string } | null, actionMeta: any) => {
        if (option !== null) {
            this.setState({ client_id: option.value });
        } else {
            this.setState({ client_id: "" });
        }
        
    }

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if ( e.currentTarget.files != null ) {
            this.setState({ project_files: e.currentTarget.files[0] });
        }
    }


    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        let button = <div></div>;

        //  Determine if we should render a message
        if ( this.state.message ) {
            button = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

        const options: { value: string; label: string; }[] = []
        this.props.clients.forEach((client) => {
            options.push({
                value: client.id,
                label: client.client_name,
            })
        })

        return(
            <div className="form-wrapper">
                {button}
                <form onSubmit={this.onSubmit}>
                    <label>Project Name</label>
                    <div className="input-wrapper projectNameField">
                        <input 
                            title='Project Name'
                            placeholder='My new project'
                            id="project_name"
                            name="project_name"
                            value={this.state.project_name}
                            onChange={this.onChangeHandler}
                        ></input>
                    </div>

                    <label>Client</label>
                    <div className="input-wrapper projectNameField">
                        <Select options={options} name='client_id' onChange={this.handleSelectChange} />
                    </div>

                    <label>Project Files</label>
                    <div className="input-wrapper dataUploadField">
                    <input
                        title="Project Files"
                        accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                        id="project_files"
                        name="project_files"
                        type="file"
                        multiple={false}
                        onChange={this.handleFileChange}
                        />
                    </div>
                <div className="whitespace seperator25"></div>
                <button className="mainButton" type="submit">Create Project</button>
            </form>
            <div className="whitespace seperator150"></div>
            </div>
        )
    }
}

export default CreateTranslationForm