import React, { Component, MouseEventHandler } from 'react'
import { Table } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
/**
 * Declare CreateProject Props
 */
type ProjectsTableProps = {
    projects: TranslationProject[],
    onRemove: (MouseEventHandler<HTMLButtonElement>),
    onLearnMore: (MouseEventHandler<HTMLButtonElement>),
    limit?: number,
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ProjectsTable extends Component<ProjectsTableProps> {

    generateImageURL = ( langCode: string ) => {
        return '/flags/' + langCode + '.svg';
    }


    render() {

        let projects = this.props.projects;

        if ( this.props.limit && this.props.limit > 0 ) {
            projects = projects?.splice(0, 5);
        }

        if ( projects ) {
            return(
                <Table striped>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Title</th>
                            <th>Target</th>
                            <th>Status</th>
                            <th>Created at</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{projects.map((project: TranslationProject) => (
                        <tr key={project.id}>
                            <td>{project.client !== null ? project.client.client_name : 'n/a'}</td>
                            <td><span className='projectName'>{project.name}</span></td>
                            <td><img className="flag" alt={project.target_lang} title={project.target_lang} src={this.generateImageURL(project.target_lang)} ></img></td>
                            <td>{project.status.replaceAll( '_', ' ' )}</td>
                            <td>{project.date_created}</td>
                            <td className='actionTD'>
                                <button title="Learn more" onClick={this.props.onLearnMore} name={project.id} className="btn altButton moreButton"><FontAwesomeIcon icon={faEllipsisH} /></button>
                                <button title="Delete Job" onClick={this.props.onRemove} name={project.id} className="btn mainButton deleteButton"><FontAwesomeIcon icon={faCircleXmark} /></button>
                            </td>
                      </tr>
                    ))}</tbody>
                    </Table>
            );
        } else {
            return (
                <p>No translation projects found yet.</p>
            );
        }
        
    }
}

export default ProjectsTable