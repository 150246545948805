import { useEffect, useState } from 'react';
import './../../../public/css/instances.css'
import 'reactjs-popup/dist/index.css';
import { useAppDispatch, useAppSelector} from '../../../hooks';
import { Navigate, useNavigate, useParams } from 'react-router';
import { fetchProjectDetail } from '../../../reducers/translationReducer';
import { Grid, Tabs, Modal } from '@mantine/core';
import ProjectStatus from '../../elements/translation/project-status';
import ProjectManagement from '../../elements/translation/project-management';
import JobTable from '../../elements/job/job-table';
import { faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditProjectForm from '../../elements/translation/edit-project-form';
import ProjectDetails from '../../elements/translation/project-details';
import { useDispatch } from 'react-redux';
import AddJobForm from '../../elements/translation/add-job-form';


/**
 * Type Definition for InstancePage properties
 */
type ProjectDetailPageProps = {
    user: LoggedUser|null
}

/**
 * Instace Page
 * Render the pages that allowed the user to view and manage his inventory Projects
 * 
 * @returns the rendered HTML
 */
const TranslationProjectDetailPage = ( props: ProjectDetailPageProps ) => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    
    const onUpdate = (project: TranslationProject) => {
        if ( project ) {
            dispatch({
                type: "UPDATE_PROJECT",
                data: project
            })
        }
    }

    const projectID = useParams().id;
    const [openedEdit, setOpenedEdit] = useState(false);
    const [openedUpload, setOpenedUpload] = useState(false);
    const projectDetailDispatch = useAppDispatch()
    useEffect(() => {
        projectDetailDispatch(fetchProjectDetail(projectID, props.user))
    },[projectDetailDispatch, projectID, props.user])

    const clients = useAppSelector(({clients}) => {
        // @ts-ignore
        return clients;
    })

    // @ts-ignore
    const project: TranslationProject = useAppSelector(({translationProject}) => {
        // @ts-ignore
        return translationProject;
    })

    const onNavigate = ( url: string ) => {
        navigate( url );
    }

    const onDelete = (job_id: string) => {
        dispatch({
            type: 'DELETE_JOB',
            data: job_id,
        });
    }

    if ( ! props.user ) {
        return ( 
            <div className="error">ERROR: Improper Request</div>
        )
    }

    if ( ! project ) {
        return (
            <div className="error">Project not found</div>
        )
    }

    if (!props.user) {
        return (
            <Navigate to={{
                pathname: '/login'
              }} />
        )
    }

    return(
        <div className="project-wrapper">
            <Modal
                opened={openedEdit}
                onClose={() => setOpenedEdit(false)}
                title={"Edit " + project.name}
            >
                <EditProjectForm clients={clients} onUpdateHandler={onUpdate} user={props.user} project={project} />
            </Modal>
            <Modal
                opened={openedUpload}
                onClose={() => setOpenedUpload(false)}
                title="Add a translation job"
            >
                <AddJobForm user={props.user} project={project} onUpdate={onUpdate} />
            </Modal>
            <Grid justify="space-between" align="center">
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 400 }}>
                    <h1>{project.name}</h1>
                    <span className='clientName'>{project.client?.client_name}</span>
                </Grid.Col>
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 300 }}>
                    <div className='btn-group'>
                        <button className="editButton mainButton" onClick={() => setOpenedEdit(true)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                        <button className="uploadButton altButton" onClick={() => setOpenedUpload(true)}><FontAwesomeIcon icon={faUpload} /> Add Job</button>
                    </div>
                </Grid.Col>
            </Grid>
            <div className="whitespace seperator25"></div>
            <ProjectStatus project_status={project.status} />
            <div className="whitespace seperator50"></div>
            <Tabs defaultValue="manage">
                <Tabs.List>
                    <Tabs.Tab value="manage">Project Management</Tabs.Tab>
                    <Tabs.Tab value="info">Project Information</Tabs.Tab>
                    <Tabs.Tab value="jobs">Translation Jobs</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="jobs" pt="xs">
                    <div className='seperator25 whitespace'></div>
                    <JobTable jobs={project.jobs} onNavigate={onNavigate} user={props.user} onDelete={onDelete} />
                </Tabs.Panel>

                <Tabs.Panel value="manage" pt="xs">
                    <ProjectManagement onUpdateHandler={onUpdate} user={props.user} project={project}/>
                </Tabs.Panel>

                <Tabs.Panel value="info" pt="xs">
                    <ProjectDetails project={project} />
                </Tabs.Panel>
            </Tabs>
            <div className='seperator50 whitespace'></div>
        </div>
    )
}

export default TranslationProjectDetailPage;
