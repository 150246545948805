import './public/css/App.css';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import LoginPage from './components/login/LoginPage';
import TranslationProjectPage from './components/pages/translation/TranslationProjectPage';
import TranslationProjectDetailPage from './components/pages/translation/ProjectDetailPage';
import AccountPage from './components/pages/AccountPage';
import AdminArea from './components/pages/admin/AdminAreaHome';
import PasswordReset from './components/login/PasswordReset';
import { MantineProvider } from '@mantine/core';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import UsersDetailWrapper from './components/pages/admin/user-detail-page';
import AppShellDemo from './components/layout/AppShell';
import Dashboard from './components/pages/Dashboard';
import RebrandingProjectPage from './components/pages/rebranding/RebrandingProjectPage';
import ClientsPage from './components/pages/clients/ClientsPage';
import './public/css/screen.css';
import ClientDetailPage from './components/pages/clients/ClientDetailPage';
import RebrandingProjectDetailPage from './components/pages/rebranding/RebrandingProjectDetailPage';


function App() {

  const [user, setUser] = useState(null)

  // Get current User
  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem('loggedUser')
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON)
      setUser(user)
    }
  }, [])


	/**
	 * Handle user logout.
	 * Reset the current user State to null and remove LocalStorage
	 */  
  	const handleLogout = () => {
    	setUser(null)
    	window.localStorage.removeItem('loggedUser')
  	}

	/**
	 * Check for 401 error when calling the backend server
	 */
  	axios.interceptors.response.use(response => {
    	return response;
  	}, error => {
    	if (error.response && error.response.status === 401) {
      		
			// token expired, destroy user session
			handleLogout()
    	}
    	return error;
  });

	return (
		<MantineProvider withGlobalStyles withNormalizeCSS>
			<Router>
				<AppShellDemo user={user} handleLogout={handleLogout} elements={
				
					<Routes>

						{/* PUBLIC ROUTES */}
						<Route path="/login" element={<LoginPage user={user} />} />
						<Route path="/login/lost-password" element={<PasswordReset email='' />} />

						{/* PRIVATE ROUTES */}
						<Route path="/" element={<Dashboard user={user} />} />

						<Route path="/clients" element={<ClientsPage user={user} />} />
						<Route path="/clients/:id" element={<ClientDetailPage user={user} />} />

            			<Route path="/translation" element={<TranslationProjectPage user={user} />} />
						<Route path="/translation/:id" element={<TranslationProjectDetailPage user={user} />} />

            			<Route path="/rebranding" element={<RebrandingProjectPage user={user} />} />
						<Route path="/rebranding/:id" element={<RebrandingProjectDetailPage user={user} />} />

            {/* 
						<Route exact path="/job" element={<JobPage user={user} />} />
						<Route path="/job/:id" element={<JobDetailPage user={user} />} />
            */}

						<Route path="/account" element={<AccountPage user={user} />} />
						
						<Route path="/admin" element={ <AdminArea user={user} />} />
						<Route path="/admin/user/:username" element={ <UsersDetailWrapper user={user} />} />
					</Routes>
				
					} 
				/>
			</Router>
		</MantineProvider>
	);
}

export default App;