import React, { Component } from 'react'
import { Table } from '@mantine/core';

/**
 * Declare CreateProject Props
 */
type ProjectDetailsProps = {
    project: TranslationProject
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ProjectDetails extends Component<ProjectDetailsProps> {

    render() {

        return(
            <div className="projectDetails-wrapper">
                <div className='seperator25 whitespace'></div>
                <Table className='detailsTable'>
                    <tbody>
                        <tr>
                            <th>Client</th>
                            <td>{this.props.project.client?.client_name}</td>
                        </tr>
                        <tr>
                            <th>Project Name</th>
                            <td>{this.props.project.name}</td>
                        </tr>
                        <tr>
                            <th>Object ID</th>
                            <td>{this.props.project.id}</td>
                        </tr>
                        <tr>
                            <th>Created</th>
                            <td>{this.props.project.date_created}</td>
                        </tr>
                        <tr>
                            <th>Author</th>
                            <td>{this.props.project.author?.first_name} {this.props.project.author?.last_name}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{this.props.project.status}</td>
                        </tr>
                        <tr>
                            <th>No. translation jobs</th>
                            <td>{this.props.project.jobs?.length}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default ProjectDetails