import React, { Component } from 'react'
import { Table } from '@mantine/core';

/**
 * Declare CreateProject Props
 */
type RebrandingDetailsProps = {
    project: RebrandingProject
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class RebrandingDetails extends Component<RebrandingDetailsProps> {

    render() {

        return(
            <div className="RebrandingDetails-wrapper">
                <div className='seperator25 whitespace'></div>
                <Table className='detailsTable'>
                    <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.props.project.id}</td>
                        </tr>
                        <tr>
                            <th>Client</th>
                            <td>{this.props.project.client?.client_name}</td>
                        </tr>
                        <tr>
                            <th>Display Name</th>
                            <td>{this.props.project.display_name}</td>
                        </tr>
                        <tr>
                            <th>Legal Name</th>
                            <td>{this.props.project.display_name}</td>
                        </tr>
                        <tr>
                            <th>Created</th>
                            <td>{this.props.project.date_created}</td>
                        </tr>
                        <tr>
                            <th>Author</th>
                            <td>{this.props.project.author?.first_name} {this.props.project.author?.last_name}</td>
                        </tr>
                        <tr>
                            <th>Locations</th>
                            <td>{this.props.project.locations.length}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default RebrandingDetails