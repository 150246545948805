import rebrandingService from "../services/rebrandingService"

const initialState = null

const rebrandingReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'ADD_REBRANDING_PROJECT':
            return [...state, action.data]
        case 'INIT_REBRANDING_PROJECTS':
            return action.data
        case 'DELETE_REBRANDING_PROJECT':
            return state.filter(e => e.id !== action.data);
        case 'UPDATE_REBRANDING':
            return state.map(e => e.id === action.data.id ? action.data : e );
        default:
            return state
    }
}

export const initializeRebrandingProjects = ( user ) => {
    return async dispatch => {
        const projects = await rebrandingService.fetchProjects( user )
        dispatch({
          type: 'INIT_REBRANDING_PROJECTS',
          data: projects ? projects: null,
        })
      }
}

export const updateProjectDetail = (project) => {
    if ( project ) {
        return async dispatch => {
            dispatch({
                type: 'UPDATE_REBRANDING',
                data: project,
            })
        }
    } else {
        return async dispatch => {
            dispatch({
                type: 'UPDATE_REBRANDING',
                data: null,
            })
        }
    }
    
}

export default rebrandingReducer