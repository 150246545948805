import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import projectService from '../../../services/translationService'
import Message from '../message'

/**
 * Declare CreateProject Props
 */
type AddJobProps = {
    user: LoggedUser,
    project: TranslationProject
    onUpdate: (project: TranslationProject) => void,
}

/**
 * Declare AccountForm State
 */
type AddJobState = {
    job_files: Blob | string,
    message: {
        type: string,
        content: string,
    } | null
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class AddJobForm extends Component<AddJobProps, AddJobState> {


    /**
     * Constructor
     * Initiante the AccountForm Component Class
     * 
     * @param props The CreateTranslationFormProps
     */
    constructor(props: AddJobProps) {
        super(props)

        this.state = {
            job_files: "",
            message: null
        }
    }

    
    /**
     * Handle user update.
     * This function is called when the user hits the submit button. 
     * Calls the backend API with the provided data and updates it
     * 
     * @param form The HTML Form Element
     */
    onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

        form.preventDefault()
           
        // define data to update
        let formData = new FormData();
        formData.append('job_files', this.state.job_files );

        let elem = this

        projectService.addJob(this.props.user, this.props.project, formData).then(function(res: any) {

            if ( res && res.id ) {
				elem.setState({
					message: {
						type: "success",
						content: "Job successfully added",
					}
				});

                elem.props.onUpdate( res );

			} else if ( res && res.message ) {
				elem.setState({
					message: {
						type: "warning",
						content: res.message,
					}
				});
			} else {
				console.log( res );
				elem.setState({
					message: {
						type: "warning",
						content: "An unknown error occured. Please check your console.",
					}
				});
			}

            
        }).catch((exception) => {
            console.log( exception );

            elem.setState({
                message: {
                    type: 'warning',
                    content: 'An unexpected error occured. Please check your console.'
                }
            });
        });

        // TODO: Update store with new project
    }


    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if ( e.currentTarget.files != null ) {
            this.setState({ job_files: e.currentTarget.files[0] });
        }
    }


    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        let button = <div></div>;

        //  Determine if we should render a message
        if ( this.state.message ) {
            button = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

        return(
            <div className="form-wrapper">
                {button}
                <form onSubmit={this.onSubmit}>

                    <label>Job XLIFF File</label>
                    <div className="input-wrapper dataUploadField">
                    <input
                        title="XLIFF File"
                        accept="xliff,xml"
                        id="job_files"
                        name="job_files"
                        type="file"
                        multiple={false}
                        onChange={this.handleFileChange}
                        />
                    </div>
                <div className="whitespace seperator25"></div>
                <button className="mainButton" type="submit"><FontAwesomeIcon icon={faPlusCircle} /> Add Job</button>
            </form>
            <div className="whitespace seperator150"></div>
            </div>
        )
    }
}

export default AddJobForm