import jobService from "../services/jobService"

const initialState = null

const jobReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'ADD_JOB':
            return [...state, action.data]
        case 'DELETE_JOB':
            return state.filter(e => e.id !== action.data);
        case 'INIT_JOBS':
            return action.data
        default:
            return state
    }
}

export const initializeJobs = ( user ) => {
    return async dispatch => {
        const jobs = await jobService.fetchJobs( user )
        dispatch({
          type: 'INIT_JOBS',
          data: jobs ? jobs: null,
        })
      }
}

export const jobDetailReducer = (state = [], action) => {
    switch(action.type) {
      case 'UPDATE_JOB':
        return action.data;
      case 'LOAD_JOB_DETAIL':
        return action.data;
      default:
        return state
    }
}

export const fetchJobDetail = (jobID, user) => {
    return async dispatch => {
        const jobDetail = await jobService.fetchJobDetails(jobID, user)
        dispatch({
            type: 'LOAD_JOB_DETAIL',
            data: jobDetail,
        }) 
    }
}

export default jobReducer