import axios from 'axios'
import config from '../config';

/**
 * Retrieve detailed user information to store in session
 * 
 * @param {object} user 
 * @returns detailed user information
 */
const getClientDetail = async (user: LoggedUser) => {

	if ( ! user ) {
		return null;
	}

	const request =  axios({
        "method": "GET",
        "url": config.api.url + "/api/user/" + user.user_object.username,
        "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
        }
    })

    const response = await request;
    return response.data;
}


/**
 * Update user data
 * 
 * @param {object} user
 * @returns The udpated user
 */
const updateClient = async (user: LoggedUser, client_id: string, formData: any ) => {

	if ( ! user ) {
		return null;
	}

  try {
    const request = axios({
        "method": "PUT",
        "url": config.api.url + "/api/client/" + client_id,
        "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
        },
        "data": formData
    })

    const response = await request;
    return response.data;

  } catch ( exception ) {
    console.log( exception );
    return exception;
  }
}

const getClients = async (user: LoggedUser) => {

    if ( ! user ) {
        return null;
    }

    try {
        const request = axios({
          "method": "GET",
          "url": config.api.url + "/api/client",
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const createClient = async (user: LoggedUser, formData: any) => {

    if ( ! user || ! formData ) {
        return null;
    }

    try {
        const request = axios({
          "method": "POST",
          "url": config.api.url + "/api/client",
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
          "data": formData
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const deleteClient = async (user: LoggedUser, id: string) => {
    if ( ! user || ! id ) {
        return null;
    }

    try {
        const request = axios({
          "method": "DELETE",
          "url": config.api.url + "/api/client/" + id,
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const clientService = {
  getClients,
  createClient,
  getClientDetail,
  updateClient,
  deleteClient,
}

export default clientService;