import React, { Component, FormEvent, useEffect } from 'react'
import { Grid, PasswordInput, Select, SimpleGrid, TextInput } from '@mantine/core';
import { getUsers } from '../../../reducers/userReducer';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Message from '../../elements/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
/**
 * Declare CreateProject Props
 */
type UsersDetailProps = {
    onUpdate: (user: TranslationUser) => void,
    user: TranslationUser,
    loggedUser: LoggedUser,
}

type EditUserFormState = {
    email: string,
    first_name: string,
    last_name: string,
    role: string,
    password: string,
    confirm_password: string,
    message: MessageProps | null
}

const UsersDetailWrapper = ( props: { user: LoggedUser|null }) => {

    let dispatch = useDispatch();
    const username = useParams().username;

    console.log( username );
    console.log( props.user );
    
    const usersDispatch = useAppDispatch();
    useEffect(() => {
        usersDispatch(getUsers(props.user))
    },[usersDispatch, props.user]);

    const user = useAppSelector( ({users}) => {
        // @ts-ignore
        return users.find((element: TranslationUser) => element.username = username);
    });

    const onUpdate = (user: TranslationUser) => {
        dispatch({
            type: 'UPDATE_USER',
            data: user,
        });
    }

    if ( user ) {
        return(
            <UsersDetailPage loggedUser={props.user!} user={user} onUpdate={onUpdate} />
        )
    } else {
        return (
            <Message messageType="warning" content="User not found" />
        );
    }
    
}

/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class UsersDetailPage extends Component<UsersDetailProps, EditUserFormState> {

    constructor( props: UsersDetailProps ) {
        super(props);

        if ( this.props.user) {

        }
        this.state = {
            first_name: this.props.user.first_name,
            last_name: this.props.user.last_name,
            email: this.props.user.email,
            role: this.props.user.role,
            password: '',
            confirm_password: '',
            message: null,
        }
    }

    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void => {
        switch( element.currentTarget.name ) {
            case 'first_name':
                this.setState({ first_name: element.currentTarget.value });
                break;
            case 'last_name':
                this.setState({ last_name: element.currentTarget.value });
                break;
            case 'email':
                this.setState({ email: element.currentTarget.value });
                break;
            case 'password':
                this.setState({ password: element.currentTarget.value });
                break;
            case 'confirm_password':
                this.setState({ confirm_password: element.currentTarget.value });
                break;
        }
    }

    onSelectHandler = ( value: string ): void => {
        this.setState({ role: value });
    }

    onSubmit = ( e: FormEvent<HTMLFormElement>) => {
        alert( this.state );
    }

    render() {

        if ( ! this.props.user ) {
            return (
                <div>User not found.</div>
            );
        } else {

            let message = <></>

            if ( this.state.message ) {
                message = <Message content={this.state.message.content} messageType={this.state.message.type} />
            }

            return(
                <div className="job-wrapper">
                    <Grid justify="space-between" align="center">
                        <Grid.Col span={2} style={{ minHeight: 80, minWidth: 900 }}><h1>{this.props.user.first_name} {this.props.user.last_name}</h1></Grid.Col>
                    </Grid>
                    <div className='seperator25 whitespace'></div>
                    <div className="job-wrapper">
                {message}
                <form onSubmit={this.onSubmit} >
                    <SimpleGrid cols={2}>
                        <TextInput
                            required
                            placeholder="Max"
                            label="First name"
                            name='first_name'
                            value={this.state.first_name}
                            onChange={this.onChangeHandler}
                        />
                        <TextInput
                            required
                            placeholder="Muster"
                            label="Last name"
                            name='last_name'
                            value={this.state.last_name}
                            onChange={this.onChangeHandler}
                        />
                    </SimpleGrid>
                    <TextInput
                        required
                        placeholder="max@muster.com"
                        label="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeHandler}
                    />
                    <Select
                        required
                        label="Role / Capabilites"
                        placeholder="Pick a role"
                        onChange={this.onSelectHandler}
                        value={this.state.role}
                        data={[
                            { value: 'translator', label: 'Translator' },
                            { value: 'admin', label: 'Administrator' },
                        ]}
                        />
                    <SimpleGrid cols={2}>
                    <PasswordInput
                        value={this.state.password}
                        required
                        placeholder="Password"
                        label="Password"
                        name='password'
                        onChange={this.onChangeHandler}
                    />
                    <PasswordInput
                        value={this.state.confirm_password}
                        required
                        placeholder="Password"
                        label="Confirm"
                        name="confirm_password"
                        onChange={this.onChangeHandler}
                    />
                    </SimpleGrid>
                    <div className='clear' />
                    <div className='seperator25 whitespace' />
                    <button title='Create User' className='mainButton' type='submit'><FontAwesomeIcon icon={faUserCheck} /> Update User</button>
                </form>
                <div className='seperator50 whitespace'></div>
            </div>
                    <div className='seperator50 whitespace'></div>
                </div>
            )
        }
        
        
    }
}

export default UsersDetailWrapper