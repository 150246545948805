import clientService from "../services/clientService";

/**
 * @var intialState The state before initializations
 */
const initialState = null


/**
 * The user reducer.
 * Fetch and hold information about the current.
 * 
 * @param {object} state The state of the data store
 * @param {string} action The action to perform
 * @returns 
 */
const clientsReducer = (state = initialState, action) => {
	switch(action.type) {
		case 'INIT_CLIENTS':
			return action.data
		case 'DELETE_CLIENT':
			return state.filter(e => e.id !== action.data);
		case 'ADD_CLIENT':
			return [...state, action.data];
		case 'UPDATE_CLIENT':
			return state.map(e => { return e.id === action.data.id ? action.data : e; });
		default:
			return state
	}
}

/**
 * Fetch user information after login
 * 
 * @param {object} user The user object retrieved after login
 * @returns the userDetailDispatch
 */
export const getUserDetail = (user) => {
	return async userDetailDispatch => {
		const userDetail = await clientService.getUserDetail(user)
		userDetailDispatch({
			type: 'INIT_USER',
			data: userDetail,
		})
	}
}

export const initializeClients = (user) => {
	return async clientsDispatch => {
		const clients = await clientService.getClients(user);
		clientsDispatch({
			type: 'INIT_CLIENTS',
			data: clients,
		})
	}
}

export default clientsReducer