import { faCheckCircle, faExclamationTriangle, faInfoCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";


/**
 * Type Definition for Message Props
 */
type MessageProps = {
    messageType: string,
    content: string,
}

type MessageState = {
    class: string,
    icon: IconDefinition,
}

/**
 * Message Component
 * A reusable component that displays differnt styles of messages (e.g. `warning` or `info`)
 */
class Message extends Component<MessageProps, MessageState> {


    /**
     * Constructor
     * Set class properties and decide what message to render
     * 
     * @param props The props for the message class
     */
    constructor(props: MessageProps) {
        super(props)

        switch ( this.props.messageType ) {
            case 'info':
                this.state = {
                    class: 'message-box info',
                    icon: faInfoCircle,
                }
                break
            case 'success':
                this.state = {
                    class: 'message-box success',
                    icon: faCheckCircle
                }
                break
            default:
            case 'warning':
                this.state = {
                    class: 'message-box warning',
                    icon: faExclamationTriangle,
                }
                break

        }
    }

    /**
     * Render the message object.
     * 
     * @returns The rendered HTML message
     */
    render() {

        return(
            <div className={this.state.class}>
                <div className="message-icon">
                    <FontAwesomeIcon icon={this.state.icon} />
                </div>
                <div className="message-text">
                    <span>{this.props.content}</span>
                </div>
            </div>
        )
    }
}

export default Message