import React, { Component } from 'react'
import clientService from '../../../services/clientService'
import Message from '../message'
import { FileInput, TextInput } from '@mantine/core';

/**
 * Declare CreateClient Props
 */
type EditClientFormProps = {
    user: LoggedUser,
    client: Client,
    onUpdateHandler: (client: Client) => void, 
}


/**
 * Declare AccountForm State
 */
type EditClientFormState = {
    client_name: string,
    client_logo: Blob | null,
    message: {
        type: string,
        content: string,
    } | null
}

/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class EditClientForm extends Component<EditClientFormProps, EditClientFormState> {


    /**
     * Constructor
     * Initiante the AccountForm Component Class
     * 
     * @param props The CreateTranslationFormProps
     */
    constructor(props: EditClientFormProps) {
        super(props)

        this.state = {
            client_name: this.props.client.client_name,
            client_logo: null,
            message: null
        }
    }

    
    /**
     * Handle user update.
     * This function is called when the user hits the submit button. 
     * Calls the backend API with the provided data and updates it
     * 
     * @param form The HTML Form Element
     */
    onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

        form.preventDefault()
        
        let formData = new FormData();
        formData.append('client_name', this.state.client_name );
        
        if ( this.state.client_logo ) {
            formData.append('client_logo', this.state.client_logo );
        }
        

        clientService.updateClient( this.props.user, this.props.client.id, formData ).then((res) => {

            if ( res && res.id ) {

                // Success
                this.setState({
                    message: {
                        type: "success",
                        content: "Client udpated"
                    }
                });

                this.props.onUpdateHandler(res);
            } else if ( res && res.message ) {

                // Failure, known error
                this.setState({
                    message: {
                        type: "warning",
                        content: res.message,
                    }
                });
            } else {
                console.log( res );

                // Failure, unknown error
                this.setState({
                    message: {
                        type: "warning",
                        content: "An unknown error occured. Please check the logs or try again later.",
                    }
                });
            }
        }).catch((exception) => {
            alert( "EXCEPTION: " + exception );
        });
        
    }


    /**
     * Handle form input changes
     * Updates the corresponding values and store the in the class's state
     * 
     * @param element The HTML Input Element
     */
    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void  => {
        this.setState({ client_name: element.currentTarget.value });
        
    }

    onFileChange = ( file: File ) => {
        this.setState({ client_logo: file });
    }


    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        let button = <div></div>;

        //  Determine if we should render a message
        if ( this.state.message ) {
            button = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

        return(
            <div className="form-wrapper">
                {button}
                <form onSubmit={this.onSubmit}>
                    <TextInput
                        placeholder={this.props.client.client_name}
                        label="Client Name"
                        value={this.state.client_name}
                        onChange={this.onChangeHandler}
                        required
                    />
                    <FileInput title="Client Files"
                        accept="image/svg+xml"
                        id="client_logo"
                        name="client_logo"
                        placeholder='Upload new SVG file' 
                        label="Logo" 
                        description="Leave empty to keep current logo" 
                        onChange={this.onFileChange}
                    />
                <div className="whitespace seperator25"></div>
                <button className="mainButton" type="submit">Save changes</button>
            </form>
            <div className="whitespace seperator150"></div>
            </div>
        )
    }
}

export default EditClientForm