import React, { Component } from 'react'
import projectService from '../../../services/translationService'
import Message from '../message'
import { TextInput, Select } from '@mantine/core';

/**
 * Declare CreateProject Props
 */
type EditProjectFormProps = {
    user: LoggedUser,
    project: TranslationProject,
    clients: Client[],
    onUpdateHandler: (project: TranslationProject) => void, 
}


/**
 * Declare AccountForm State
 */
type EditProjectFormState = {
    project_name: string,
    project_status: string,
    client_id: string,
    message: {
        type: string,
        content: string,
    } | null
}

/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class EditProjectForm extends Component<EditProjectFormProps, EditProjectFormState> {


    /**
     * Constructor
     * Initiante the AccountForm Component Class
     * 
     * @param props The CreateTranslationFormProps
     */
    constructor(props: EditProjectFormProps) {
        super(props)

        this.state = {
            project_name: this.props.project.name,
            project_status: this.props.project.status,
            client_id: this.props.project.client?.id ? this.props.project.client?.id : '',
            message: null
        }
    }

    
    /**
     * Handle user update.
     * This function is called when the user hits the submit button. 
     * Calls the backend API with the provided data and updates it
     * 
     * @param form The HTML Form Element
     */
    onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

        form.preventDefault()

        projectService.updateProject( this.props.user, {
            project_id: this.props.project.id,
            project_name: this.state.project_name,
            project_status: this.state.project_status,
        }).then((res) => {

            if ( res && res.id ) {

                // Success
                this.setState({
                    message: {
                        type: "success",
                        content: "Project udpated"
                    }
                });

                this.props.onUpdateHandler( res );
            } else if ( res && res.message ) {

                // Failure, known error
                this.setState({
                    message: {
                        type: "warning",
                        content: res.message,
                    }
                });
            } else {
                console.log( res );

                // Failure, unknown error
                this.setState({
                    message: {
                        type: "warning",
                        content: "An unknown error occured. Please check the logs or try again later.",
                    }
                });
            }
        }).catch((exception) => {
            alert( "EXCEPTION: " + exception );
        });
        
    }


    /**
     * Handle form input changes
     * Updates the corresponding values and store the in the class's state
     * 
     * @param element The HTML Input Element
     */
    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void  => {

        this.setState({ project_name: element.currentTarget.value });
        
    }

    onStatusChangeHandler = ( value: string ) => {
        this.setState({ project_status: value });
    }


    handleSelectChange = (value: string | null) => {
        if (value !== null) {
            this.setState({ client_id: value });
        } else {
            this.setState({ client_id: "" });
        }
        
    }


    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        let button = <div></div>;

        //  Determine if we should render a message
        if ( this.state.message ) {
            button = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

        const options: { value: string; label: string; }[] = []
        this.props.clients.forEach((client) => {
            options.push({
                value: client.id,
                label: client.client_name,
            })
        })

        return(
            <div className="form-wrapper">
                {button}
                <form onSubmit={this.onSubmit}>
                <Select label='Client' placeholder='Bitte wählen' data={options} name='client_id' onChange={this.handleSelectChange} value={this.state.client_id} />
                    <TextInput
                        placeholder="Project Name"
                        label={this.props.project.name}
                        value={this.state.project_name}
                        onChange={this.onChangeHandler}
                        required
                    />
                    <Select
                        label="Project Status"
                        placeholder="Pick a status"
                        onChange={this.onStatusChangeHandler}
                        value={this.state.project_status}
                        data={[
                            { 
                                value: 'Created', 
                                label: 'Created', 
                                description: "New project" 
                            },
                            { 
                                value: 'Pretranslated', 
                                label: 'Pretranslated', 
                                description: "Already processed by DeepL" 
                            },
                            { 
                                value: 'Translations_In_Correction', 
                                label: "XLSX files exported", 
                                description: 'XLSX files are in correction' 
                            },
                            { 
                                value: 'Translations_Corrected', 
                                label: "Translations finalized",
                                description: 'Corrected XLSX files have been imported' 
                            },
                            { 
                                value: 'Completed', 
                                label: 'Completed',
                                description: "XLIFF files have been exported for WPML",
                            },
                        ]}
                    />
                <div className="whitespace seperator25"></div>
                <button className="mainButton" type="submit">Save changes</button>
            </form>
            <div className="whitespace seperator150"></div>
            </div>
        )
    }
}

export default EditProjectForm