import React, { Component } from 'react'
import { Table } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import jobService from '../../../services/jobService';
/**
 * Declare CreateProject Props
 */
type JobTableProps = {
    user: LoggedUser,
    jobs?: TranslationJob[],
    onNavigate: (url: string) => void,
    onDelete: (job_id: string) => void,
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class JobTable extends Component<JobTableProps> {

    generateImageURL = ( langCode: string ) => {
        return '/flags/' + langCode + '.svg';
    }

    onLearnMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button = event.currentTarget;

        this.props.onNavigate( "/job/" + button.name );
    }

    onDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;

        if ( window.confirm( "Are you sure you wish to delete this project? This cannot be undone." ) !== true ) {
            return;
        }

        jobService.deleteJob(this.props.user, button.name).then( ( res ) => {

            if ( res && res.message ) {
                alert( "Could not delete job: " + res.message );
            } else {
                this.props.onDelete( button.name );
            }
        }).catch((exception) => {
            console.log( exception );
            alert( "Could not delete project. Please check your console for more info" );
        });
    }

    render() {

        if ( this.props.jobs ) {
            return(
                <Table striped>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Source Language</th>
                            <th>Target Language</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.props.jobs.map((job) => (
                        <tr key={job.wpml_id}>
                            <td><span className='jobFileName'>{job.file_name}</span></td>
                            <td>{job.post_type}</td>
                            <td><img className="flag" alt={job.source_lang} title={job.source_lang} src={this.generateImageURL(job.source_lang)} ></img></td>
                            <td><img className="flag" alt={job.target_lang} title={job.target_lang} src={this.generateImageURL(job.target_lang)} ></img></td>
                            <td className='actionTD'>
                                <button title="Learn more" name={job.id} onClick={this.onLearnMore} className="btn altButton moreButton"><FontAwesomeIcon icon={faEllipsisH} /></button>
                                <button title="Delete Job" name={job.id} onClick={this.onDelete} className="btn mainButton deleteButton"><FontAwesomeIcon icon={faCircleXmark} /></button>
                            </td>
                      </tr>
                    ))}</tbody>
                    </Table>
            );
        } else {
            return (
                <p>No jobs for this project.</p>
            );
        }
        
    }
}

export default JobTable