import React, { Component } from 'react'

/**
 * Declare CreateProject Props
 */
type ColorSchemeProps = {
    project: RebrandingProject
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ColorScheme extends Component<ColorSchemeProps> {

    colorOutput = ( color: Color ) => {
        return (
        <div className="color-wrapper">
            <div className="color-spot" style={{ background: color.hex }}></div>
            <div className="color-info-wrapper">
                <table className="color-table">
                    <tr>
                        <th>cmyk</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>rgb</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th>hex</th>
                        <td>{color.hex}</td>
                    </tr>
                </table>
            </div>
        </div>
        )
    }

    render() {

        return(
            <div className="ColorScheme-wrapper">
                <div className='seperator25 whitespace'></div>
                <div className="row">
                <div className="logo-container">
                    <span className='clientLogo' dangerouslySetInnerHTML={{__html: this.props.project.client ? this.props.project.client.client_logo : ''}}></span>
                </div>
            </div>
            <div>
                <h2>Priority 1</h2>
            </div>
            <div className="flex-row color-row">
                {this.props.project.color_scheme.prio1_colors.map( (color ) => {
                    return this.colorOutput( color );
                })}
            </div>
            <div>
                <h2>Priority 2</h2>
            </div>
            <div className="flex-row color-row">
                {this.props.project.color_scheme.prio2_colors.map( (color ) => {
                    return this.colorOutput( color );
                })}
            </div>
            <h2>Highlight</h2>
            <div className="flex-row color-row">
                {this.colorOutput( this.props.project.color_scheme.highlight_color )}
            </div>
            <h2>Corporate</h2>
            <div className='flex-row color-row'>
                {this.props.project.color_scheme.greyscale_colors.map( (color ) => {
                    return this.colorOutput( color );
                })}
            </div>
                
            </div>
        )
    }
}

export default ColorScheme