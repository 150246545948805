import { ReactNode } from 'react';
import logo from './../../public/logo.svg';
import logoAlt from './../../public/logo-alt.svg';
import {
    TablerIcon,
    IconGauge,
    IconSettings,
    IconLogout,
    IconLanguage,
    IconUsers,
    IconColorSwatch,
    IconUserCircle,
  } from '@tabler/icons';
import {
    AppShell,
    Navbar,
    Header,
    Footer,
    useMantineTheme,
    createStyles,
    Anchor,
    Container,
    Group,
    Stack,
    Tooltip,
    UnstyledButton
} from '@mantine/core';
import { NavLink } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    header: {},

    headerLogo: {
        width: '60px',
        height: 'auto',
    },

    footer: {
        background: '#e11b22',
        color: '#fff',
    },

    footerLogo: {
        width: '40px',
        height: 'auto',
    },

    inner: {
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '12px',
        paddingBottom: '12px',
        maxWidth: '100%',
        paddingLeft: '35px',
        paddingRight: '35px',

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    headerInner: {
        color: '#000',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '12px',
        paddingBottom: '12px',
        maxWidth: '100%',
        paddingLeft: '115px',
        paddingRight: '35px',

        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column',
        },
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            marginTop: theme.spacing.md,
        },
    },

    link: {
        color: '#fff',
    },

    navLink: {
        width: 50,
        height: 50,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    
        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
      },
    
      active: {
        '&, &:hover': {
          backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
          color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
      },
}));

interface NavbarLinkProps {
    icon: TablerIcon;
    label: string;
    target: string,
    onClick: () => void,
}
  
function NavbarLink({ icon: Icon, label, target, onClick }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
    
    if ( target === '/logout' ) {
        return (
    
            <Tooltip label={label} position="right" transitionDuration={0}>
                <UnstyledButton onClick={onClick} title="Log out" className={classes.navLink} >
                    <Icon stroke={1.5} />
                </UnstyledButton>
            </Tooltip>
        );
    } else {
        return (
    
            <Tooltip label={label} position="right" transitionDuration={0}>
                <NavLink to={target} className={
                    (link) => {
                        return cx(classes.navLink, { [classes.active]: link.isActive })
                    }
                }>
                    <Icon stroke={1.5} />
                </NavLink>
            </Tooltip>
        );
    }
}

export default function AppShellDemo(props: { elements: ReactNode, user: LoggedUser|null, handleLogout: () => void }) {

    const theme = useMantineTheme();

    const footerLinks = [
        { label: 'Legal Information', link: 'https://cooper-media.com/' },
        { label: 'Privacy Policy', link: 'https://cooper-media.com/' },
        { label: 'Support & Info', link: 'https://cooper-media.com/' },
    ]

    const endpoints = [
        { icon: IconGauge, label: 'Dashboard', target: '/' },
        { icon: IconUsers, label: 'Clients', target: '/clients' },
        { icon: IconLanguage, label: 'Translation', target: '/translation' },
        { icon: IconColorSwatch, label: 'Rebranding', target: '/rebranding' },
        { icon: IconUserCircle, label: 'Account', target: '/account' },
    ];

    if ( props.user?.user_object.role === "admin" ) {
        endpoints.push({ icon: IconSettings, label: 'Administration', target: '/admin' });
    }

    const navbarLinks = endpoints.map((link, index) => (
            <NavbarLink
                key={link.target}
                onClick={() => {}}
                {...link}
            />
      ));

    const { classes } = useStyles();
    const footerItems = footerLinks.map((link) => (
        <Anchor<'a'>
            color="dimmed"
            className={classes.link}
            key={link.label}
            href={link.link}
            onClick={(event) => event.preventDefault()}
            size="sm"
        >
            {link.label}
        </Anchor>
    ));

    let greeting = <></>

    if ( props.user ) {
        greeting = <p>Howdy, {props.user.user_object.first_name + " " + props.user.user_object.last_name}!</p>
    }

    let navbar = <></>

    if ( props.user ) {
        navbar = <Navbar  width={{ base: 80 }} p="md">
            <Navbar.Section grow mt={20}>
                <Stack justify="center" spacing={0}>
                {navbarLinks}
                </Stack>
            </Navbar.Section>
            <Navbar.Section>
                <Stack justify="center" spacing={0}>
                    <NavbarLink icon={IconLogout} label="Logout" target="/logout" onClick={props.handleLogout} />
                </Stack>
            </Navbar.Section>
        </Navbar>
    }

    return (
        <AppShell
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.white,
                },
            }}
            className={props.user ? 'loggedIn' : 'login'}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            navbar={navbar}
            footer={
                <Footer height={60}>
                    <div className={classes.footer}>
                        <Container className={classes.inner}>
                            <img className={classes.footerLogo} src={logoAlt} alt="Logo"/>
                            <Group className={classes.links}>{footerItems}</Group>
                        </Container>
                    </div>
                </Footer>
            }
            header={
                <Header height={80} p="lg" style={{padding: 'initial'}}>
                    <div className={classes.header}>
                        <Container className={classes.headerInner}>
                            <img className={classes.headerLogo} src={logo} alt="Logo"/>
                            <Group className={classes.links}>{greeting}</Group>
                        </Container>
                    </div>
                </Header>
            }
        >
            {props.elements}
        </AppShell>
    );
}