import { useState } from 'react';
import './../../../public/css/instances.css'
import 'reactjs-popup/dist/index.css';
import { useAppSelector} from '../../../hooks';
import { Navigate, useParams } from 'react-router';
import { Grid, Tabs, Modal } from '@mantine/core';
import { faEdit, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateRebrandingMultiform from '../../elements/rebranding/create-rebranding-multiform';
import RebrandingDetails from '../../elements/rebranding/project-details';
import ColorScheme from '../../elements/rebranding/color-scheme';
import Locations from '../../elements/rebranding/locations';
import rebrandingService from '../../../services/rebrandingService';


/**
 * Type Definition for InstancePage properties
 */
type ProjectDetailPageProps = {
    user: LoggedUser|null
}

/**
 * Instace Page
 * Render the pages that allowed the user to view and manage his inventory Projects
 * 
 * @returns the rendered HTML
 */
const RebrandingProjectDetailPage = ( props: ProjectDetailPageProps ) => {

    const projectID = useParams().id;
    const [openedEdit, setOpenedEdit] = useState(false);


    // @ts-ignore
    const project: RebrandingProject = useAppSelector(({rebrandingProjects}) => {
        return rebrandingProjects.find( (e: RebrandingProject) => { return e.id === projectID });
    })

    const clients: Array<Client> = useAppSelector(({clients}) => {
        return clients;
    })

    if ( ! props.user ) {
        return ( 
            <div className="error">ERROR: Improper Request</div>
        )
    }

    if ( ! project ) {
        return (
            <div className="error">Project not found</div>
        )
    }

    if (!props.user) {
        return (
            <Navigate to={{
                pathname: '/login'
              }} />
        )
    }

    const onDownloadPackageClick = () => {
        rebrandingService.downloadPackage( props.user!, project ).then(function(url: any) {
			if ( url ) {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', project.display_name + '-rebranding.zip');
                document.body.appendChild(link);
                link.click();
            } else {
                alert( "Failed to download rebranding files for this project. Please check the logs" )
            }
		}).catch(function(e) {
            console.log( e );
            alert( "Failed to make request for the rebranding files. Please check your console for more info." );
        })
    }

    return(
        <div className="project-wrapper">
            <Modal
                opened={openedEdit}
                size="auto"
                onClose={() => setOpenedEdit(false)}
                title={"Edit " + project.display_name}
            >
                <CreateRebrandingMultiform clients={clients} user={props.user} project={project} />
            </Modal>
            <Grid justify="space-between" align="center">
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 400 }}>
                    <h1>{project.display_name}</h1>
                    <span className='clientName'>{project.client?.client_name}</span>
                </Grid.Col>
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 400 }}>
                    <div className='btn-group'>
                        <button className="editButton mainButton" onClick={() => setOpenedEdit(true)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                        <button className="uploadButton altButton" onClick={onDownloadPackageClick}><FontAwesomeIcon icon={faFileDownload} /> Download Package</button>
                    </div>
                </Grid.Col>
            </Grid>
            <div className="whitespace seperator25"></div>
            <div className="whitespace seperator50"></div>
            <Tabs defaultValue="info">
                <Tabs.List>
                    <Tabs.Tab value="info">Project Information</Tabs.Tab>
                    <Tabs.Tab value="color_scheme">Color Scheme</Tabs.Tab>
                    <Tabs.Tab value="locations">Locations</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="info" pt="xs">
                    <RebrandingDetails project={project} />
                </Tabs.Panel>
                <Tabs.Panel value="color_scheme" pt="xs">
                    <ColorScheme project={project} />
                </Tabs.Panel>
                <Tabs.Panel value="locations" pt="xs">
                    <Locations project={project} />
                </Tabs.Panel>
            </Tabs>
            <div className='seperator50 whitespace'></div>
        </div>
    )
}

export default RebrandingProjectDetailPage;
