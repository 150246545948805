import React, { Component } from 'react'
import projectService from '../../services/translationService';
import Message from '../elements/message';
import './../../public/css/instances.css';


/**
 * Type definition for AddInstance Class properties
 */
type ImportXLSXProps = {
	user: LoggedUser,
	project: TranslationProject,
	onUpdate: (project: TranslationProject) => void,
}

type ImportXLSXState = {
	xlsx_files: Blob | string,
	message: {
		type: string,
		content: string,
	} | null
}

/**
 * AddInstance component
 * Used to turn a valid license into an instance
 */
class ImportXLSX extends Component<ImportXLSXProps, ImportXLSXState> {

	/**
	 * Construct the ImportXLSX Class
	 * Sets the state of the instance.
	 * 
	 * @param props the login properties as define above
	 */
	 constructor(props: ImportXLSXProps) {
		super(props)
		
		this.state = {
			xlsx_files: "",
			message: null,
		}
	}

	onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

		form.preventDefault()
           
        // define data to update
        let formData = new FormData();
        formData.append('xlsx_files', this.state.xlsx_files );

        let elem = this

		projectService.importXLSX( this.props.user, this.props.project, formData ).then(function(res: any) {
			
			if ( res && res.id ) {
				elem.setState({
					message: {
						type: "success",
						content: "Project successfully updated",
					}
				});

				elem.props.onUpdate( res );
			} else if ( res && res.message ) {
				elem.setState({
					message: {
						type: "warning",
						content: res.message,
					}
				});
			} else {
				console.log( res );
				elem.setState({
					message: {
						type: "warning",
						content: "An unknown error occured. Please check your console.",
					}
				});
			}
		})
	}

	handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if ( e.currentTarget.files != null ) {
            this.setState({ xlsx_files: e.currentTarget.files[0] });
        }
    }

	/**
	 * Render the Component
	 * 
	 * @returns The HTML for the rendered component
	 */
	render() {

		let message = <span></span>

		if ( this.state.message ) {
			message = <Message messageType={this.state.message.type} content={this.state.message.content} />
		} 

		// let the user choose which license to create the instance for
		return (
			<div className="addInstance chooseLicense wrapper">
				{message}
				<div className="form-wrapper">
					<form onSubmit={this.onSubmit}>
						<label>XLSX Files</label>
						<div className="input-wrapper dataUploadField">
						<input
							title="Project Files"
							accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
							id="xlsx_files"
							name="xlsx_files"
							type="file"
							multiple={false}
							onChange={this.handleFileChange}
							/>
						</div>
						<div className="whitespace seperator25"></div>
						<button className="mainButton" type="submit">Import XLSX Archive</button>
					</form>
				</div>
			</div>
		)
		
	}
}

export default ImportXLSX
