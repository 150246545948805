import { useState } from 'react';
import './../../../public/css/instances.css'
import 'reactjs-popup/dist/index.css';
import { useAppSelector} from '../../../hooks';
import { Navigate, useNavigate, useParams } from 'react-router';
import { Grid, Tabs, Modal } from '@mantine/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import ClientDetails from '../../elements/clients/client-details';
import ProjectsTable from '../../elements/translation/projects-table';
import RebrandingProjectsTable from '../../elements/rebranding/rebranding-projects-table';
import EditClientForm from '../../elements/clients/edit-client-form';


/**
 * Type Definition for InstancePage properties
 */
type ClientDetailPageProps = {
    user: LoggedUser|null
}

/**
 * Instace Page
 * Render the pages that allowed the user to view and manage his inventory Projects
 * 
 * @returns the rendered HTML
 */
const ClientDetailPage = ( props: ClientDetailPageProps ) => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    
    const onUpdate = (client: Client) => {
        if ( client ) {
            dispatch({
                type: "UPDATE_CLIENT",
                data: client
            })
        }
    }

    const clientID = useParams().id;
    const [openedEdit, setOpenedEdit] = useState(false);

    const client: Client = useAppSelector(({clients}) => {
        // @ts-ignore
        return clients.find(e => e.id === clientID);
    })

    const translationProjects: Array<TranslationProject> = useAppSelector(({translationProjects}) => {
        return translationProjects.filter( (e: TranslationProject) => e.client?.id === clientID);
    })

    const rebrandingProjects: Array<RebrandingProject> = useAppSelector(({rebrandingProjects}) => {
        return rebrandingProjects.filter( (e: RebrandingProject) => e.client?.id === clientID );
    })

    const navigateTranslation = ( event: React.MouseEvent<HTMLButtonElement> ) => {
        event.preventDefault();
        navigate( "/translation/" + event.currentTarget.name );
    }

    const navigateRebranding = ( event: React.MouseEvent<HTMLButtonElement> ) => {
        event.preventDefault();
        navigate( "/rebranding/" + event.currentTarget.name );
    }

    if ( ! props.user ) {
        return ( 
            <div className="error">ERROR: Improper Request</div>
        )
    }

    if ( ! client ) {
        return (
            <div className="error">Client not found</div>
        )
    }

    if (!props.user) {
        return (
            <Navigate to={{
                pathname: '/login'
              }} />
        )
    }

    return(
        <div className="project-wrapper">
            <Modal
                opened={openedEdit}
                onClose={() => setOpenedEdit(false)}
                title={"Edit " + client.client_name}
            >
                <EditClientForm onUpdateHandler={onUpdate} user={props.user} client={client} />
            </Modal>
            <Grid justify="space-between" align="center">
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 400 }}>
                    <span className="clientLogo" dangerouslySetInnerHTML={{__html: client.client_logo}}></span>
                </Grid.Col>
                <Grid.Col span={2} style={{ minHeight: 80, minWidth: 300 }}>
                    <div className='btn-group'>
                        <button className="editButton mainButton" onClick={() => setOpenedEdit(true)}><FontAwesomeIcon icon={faEdit} /> Edit Client</button>
                    </div>
                </Grid.Col>
            </Grid>
            <div className="whitespace seperator25"></div>
            <div className="whitespace seperator50"></div>
            <Tabs defaultValue="info">
                <Tabs.List>
                    <Tabs.Tab value="info">Client Info</Tabs.Tab>
                    <Tabs.Tab value="translation">Translation projects</Tabs.Tab>
                    <Tabs.Tab value="rebranding">Rebranding projects</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="info" pt="xs">
                    <div className='seperator25 whitespace'></div>
                    <ClientDetails client={client} />
                </Tabs.Panel>

                <Tabs.Panel value="translation" pt="xs">
                    <ProjectsTable projects={translationProjects} onRemove={()=>{}} onLearnMore={navigateTranslation}/>
                </Tabs.Panel>

                <Tabs.Panel value="rebranding" pt="xs">
                    <RebrandingProjectsTable projects={rebrandingProjects} onLearnMore={navigateRebranding} onRemove={()=>{}} />
                </Tabs.Panel>
            </Tabs>
            <div className='seperator50 whitespace'></div>
        </div>
    )
}

export default ClientDetailPage;
