import projectService from "../services/translationService"

const initialState = null

const projectReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'ADD_PROJECT':
            return [...state, action.data]
        case 'INIT_PROJECTS':
            return action.data
        case 'DELETE_PROJECT':
            return state.filter(e => e.id !== action.data);
        default:
            return state
    }
}

export const initializeTranslationProjects = ( user ) => {
    return async dispatch => {
        const projects = await projectService.fetchProjects( user )
        dispatch({
          type: 'INIT_PROJECTS',
          data: projects ? projects: null,
        })
      }
}

export const projectDetailReducer = (state = null, action) => {
    switch(action.type) {
      case 'UPDATE_PROJECT':
        return action.data;
      case 'LOAD_PROJECT_DETAIL':
        return action.data;
      default:
        return null;
    }
}

export const fetchProjectDetail = (projectID, user) => {
    return async dispatch => {
        const projectDetail = await projectService.fetchProjectDetail(projectID, user)
        dispatch({
            type: 'LOAD_PROJECT_DETAIL',
            data: projectDetail,
        }) 
    }
}

export const updateProjectDetail = (project) => {
    if ( project ) {
        return async dispatch => {
            dispatch({
                type: 'UPDATE_PROJECT',
                data: project,
            })
        }
    } else {
        return async dispatch => {
            dispatch({
                type: 'UPDATE_PROJECT',
                data: null,
            })
        }
    }
    
}

export default projectReducer