import React, { Component, useState } from 'react';
import './../../public/css/login.css';
import { NavLink, Navigate } from 'react-router-dom';
import loginService from '../../services/loginService';
import Message from '../elements/message';
import { LoadingOverlay } from '@mantine/core';


/**
 * Define Login Prop types
 */
type LoginProps = {
	visible: boolean,
	setVisible: (visible: boolean) => void,
}

/**
 * Define Login State
 */
type LoginState = {
	username: string,
	password: string,
	message: MessageProps | null,
}

const LoginPageWrapper = (props: { user: LoggedUser|null } ) => {
	const [visible, setVisible] = useState(false);

	if ( props.user ) {
		return( <Navigate to = "/" /> );
	} else {
		return( <LoginPage visible={visible} setVisible={setVisible} /> );
	}
}

/**
 * The Login Page Class
 */
class LoginPage extends Component<LoginProps, LoginState> {


	/**
	 * Construct the LoginPage Class
	 * Sets the state of the instance.
	 * 
	 * @param props the login properties as define above
	 */
	constructor(props: LoginProps) {
		super(props)
		
		this.state = {
			username: '',
			password: '',
			message: null,
		}

	}


	/**
	 * Handle changes in the email field
	 * Update the email in the element's store
	 * 
	 * @param element The HTMLInputElement (Email field)
	 */
	onEmailChange = (element: React.FormEvent<HTMLInputElement>): void => {
		this.setState({ username: element.currentTarget.value })
	}


	/**
	 * Handle changes in the password field
	 * Update the password in the element's store
	 * 
	 * @param element The HTMLInputElement (Password field)
	 */
	onPasswordChange = (element: React.FormEvent<HTMLInputElement>): void => {
		this.setState({ password: element.currentTarget.value })
	}


	/**
	 * Handle Login
	 * Verify user credentials and, if successful, create a user session
	 * 
	 * @param form The form element with the field-data
	 * @returns 
	 */
	onSubmit = async (form: React.FormEvent<HTMLFormElement>) => {

		this.setState( { message: null } );
		this.props.setVisible( true );
		form.nativeEvent.preventDefault()

		const username = this.state.username
		const password = this.state.password
		
		try {
			let loggedUser = await loginService.login({
				username: username,
				password: password,
			})

			this.props.setVisible( false );

			if ( ! loggedUser ) {
				// unsuccessful login. Set error message and reset password
				this.setState({
					password: '',
					message: {
						type: 'warning',
						content: 'Username or password incorrect.',
					}
				})
				return
			}


			// Create local session storage
			window.localStorage.setItem('loggedUser', JSON.stringify(loggedUser))

			// redirect to home
			window.location.href = "/";
		} catch(exception: unknown) {

			console.log(exception)
			
			// unsuccessful login. Set error message and reset password
			this.setState({
				password: '',
				message: {
					content: 'An unknown error occured. Please check your console.',
					type: 'warning',
				}
			})
		}
	}


	/**
	 * Render the Login Page HHTML
	 * 
	 * @returns the rendered HTML for the login page
	 */
	render() {
		let message = <></>

		if ( this.state.message ) {
			message = <Message messageType={this.state.message.type} content={this.state.message.content} />
		}
			
		// If the user NOT is logged in, show the login page
		return (
			<div className="wrapper">
				<LoadingOverlay visible={this.props.visible} overlayBlur={2} />
				<div className="box">
				<div className="loginTitle">
					<span>Sign in to your account</span>
				</div>
				{message}
				<div className="box-row">
					<div className="box-right">
					<form onSubmit={this.onSubmit}>
						<label>Username</label>
						<div className="input-wrapper">
							<input
							required
							title='Username'
							type="text"
							value={this.state.username}
							name="Username"
							key='username'
							onChange={this.onEmailChange}
							autoComplete="username"
							/>
						</div>
						<label className='passwordLabel'>Password</label>
						<NavLink to="/login/lost-password?email=" >
							<span className="pw-reset">Forgot Password?</span>
						</NavLink>
						<div className="input-wrapper">
							<input
							required
							title='Password'
							type="password"
							value={this.state.password}
							name="Password"
							key='password'
							onChange={this.onPasswordChange}
							autoComplete="current-password"
							/>
						</div>
						<button className="submit-btn" type="submit">Log In</button>
					</form>
					<div className="legal">
						<a href="https://cooper-media.com" target="_blank" rel="noreferrer noopener">cooper media GmbH</a>
						<span className="seperator">|</span>
						<a href="https://cooper-media.com/legal-notice" target="_blank" rel="noreferrer noopener">Legal Notice</a>
						<span className="seperator">|</span>
						<a href="https://cooper-media.com/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>
					</div>
					</div>
				</div>
				</div>
			</div>
		)
	}
}
  
  
export default LoginPageWrapper;