import React, { Component } from 'react'
import userService from '../../services/userService'
import Message from './message'

/**
 * Declare AccountForm Props
 */
type AccountFormProps = {
    user: LoggedUser
}

/**
 * Declare AccountForm State
 */
type AccountFormState = {
    first_name: string,
    last_name: string,
    email: string,
    role: string,
    token: string,
    password: string,
    confirmPassword: string,
    message: {
        type: string,
        content: string,
    } | null
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class AccountForm extends Component<AccountFormProps, AccountFormState> {


    /**
     * Constructor
     * Initiante the AccountForm Component Class
     * 
     * @param props The AccountFormProps
     */
    constructor(props: AccountFormProps) {
        super(props)

        this.state = {
            first_name: this.props.user.user_object.first_name,
            last_name: this.props.user.user_object.last_name,
            email: this.props.user.user_object.email,
            role: this.props.user.user_object.role,
            token: this.props.user.user_object.token ? this.props.user.user_object.token : "",
            password: '',
            confirmPassword: '',
            message: null
        }
    }

    
    /**
     * Handle user update.
     * This function is called when the user hits the submit button. 
     * Calls the backend API with the provided data and updates it
     * 
     * @param form The HTML Form Element
     */
    onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

        form.preventDefault()
           
        // define data to update
        let updatedUser: UserUpdatePayload = {
            email: this.state.email,
            username: this.props.user.user_object.username,
            token: this.state.token,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            role: this.state.role,
        }

        // determine if password should be updated
        if ( this.state.password && this.state.password === this.state.confirmPassword ) {
            updatedUser.password = this.state.password
            updatedUser.confirm_password = this.state.confirmPassword
        }

        let elem = this

        // call API to update user
        userService.updateUser(this.props.user, updatedUser).then(function(res: any) {

            if ( res instanceof Error ) {

                // @ts-ignore
                if ( res.response ) {

                    // Server responded with an error
                    elem.setState({
                        message: {
                            type: 'warning',
                            // @ts-ignore
                            content: res.response.data.error
                        }
                    })
                } else {

                    // Failed to contact the server
                    elem.setState({
                        message: {
                            type: 'warning',
                            content: 'Failed to update account information. Please try again later'
                        }
                    })
                }
                

            } else if ( res && res.data ) {
                // TODO: Update loggedUser useState

                // If successful, let the user know
                elem.setState({
                    message: {
                        type: 'success',
                        content: 'Account information updated'
                    }
                })
            } else {

                // unexpected!
                elem.setState({
                    message: {
                        type: 'warning',
                        content: 'An unexpected error occured. Please reload the page and try again'
                    }
                })
            }
        })
    }


    /**
     * Handle form input changes
     * Updates the corresponding values and store the in the class's state
     * 
     * @param element The HTML Input Element
     */
    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void  => {

        switch ( element.currentTarget.name ) {
            case 'firstname':
                this.setState({ first_name: element.currentTarget.value })
                break;
            case 'lastname':
                this.setState({ last_name: element.currentTarget.value })
                break;
            case 'email':
                this.setState({ email: element.currentTarget.value })
                break
            case 'password':
                    // TODO Make sure password matches with confirmPassword
                    this.setState({ password: element.currentTarget.value })
                    break
            case 'confirmPassword':
                this.setState({ confirmPassword: element.currentTarget.value })
                break

        }
        
    }


    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        let message = <div></div>;

        //  Determine if we should render a message
        if ( this.state.message ) {
            message = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

        return(
            <div className="form-wrapper">
                {message}
                <form onSubmit={this.onSubmit}>
                <div className="form_left_half">
                    <label>Firstname</label>
                    <div className="input-wrapper firstnameField">
                        <input
                            title='First name'
                            id="firstname"
                            name="firstname"
                            value={this.state.first_name}
                            onChange={this.onChangeHandler}
                        ></input>
                    </div>
                </div>
                <div className="form_right_half">
                    <label>Lastname</label>
                    <div className="input-wrapper lastnameField">
                        <input
                            title='Last name'
                            id="lastname"
                            name="lastname"
                            onChange={this.onChangeHandler}
                            value={this.state.last_name}
                        ></input>
                    </div>
                </div>

                <div className="form">
                    <label>Email</label>
                    <div className="input-wrapper emailField">
                        <input
                            title='Email'
                            id="email"
                            name="email"
                            onChange={this.onChangeHandler}
                            value={this.state.email}
                        ></input>
                    </div>
                </div>
            
                <div className="form_left_half">
                    <label>New Password (optional)</label>
                    <div className="input-wrapper zipcodeField">
                        <input
                            title='New Password'
                            id="password"
                            name="password"
                            onChange={this.onChangeHandler}
                            value={this.state.password}
                        ></input>
                    </div>
                </div>
                    <div className="form_right_half">
                    <label>Confirm new Password (optional)</label>
                    <div className="input-wrapper cityField">
                        <input
                            title='Confirm Password'
                            id="confirmPassword"
                            name="confirmPassword"
                            onChange={this.onChangeHandler}
                            value={this.state.confirmPassword}
                        ></input>
                    </div>
                </div>
                <div className="whitespace seperator25"></div>
                <button className="mainButton" type="submit">Update Account Info</button>
            </form>
            <div className="whitespace seperator150"></div>
            </div>
        )
    }
}

export default AccountForm