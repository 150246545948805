import React, { Component, FormEvent } from 'react'
import { LoadingOverlay, PasswordInput, Select, SimpleGrid, TextInput } from '@mantine/core';
import Message from '../../elements/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import userService from '../../../services/userService';
/**
 * Declare CreateProject Props
 */
type AddUserProps = {
    onCreate: (user: TranslationUser) => void,
    user: LoggedUser,
    showLoading: boolean,
    setShowLoading: (show: boolean) => void,
}

type AddUserState = {
    username: string,
    email: string,
    first_name: string,
    last_name: string,
    role: string,
    password: string,
    confirm_password: string,
    message: MessageProps | null,
}

class AddUserForm extends Component<AddUserProps, AddUserState> {


    constructor(props: AddUserProps) {
        super(props)

        this.state = {
            username: '',
            email: '',
            first_name: '',
            last_name: '',
            role: '',
            password: '',
            confirm_password: '',
            message: null,
        };
    }

    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void => {
        switch( element.currentTarget.name ) {
            case 'first_name':
                this.setState({ first_name: element.currentTarget.value });
                break;
            case 'last_name':
                this.setState({ last_name: element.currentTarget.value });
                break;
            case 'username':
                this.setState({ username: element.currentTarget.value });
                break;
            case 'email':
                this.setState({ email: element.currentTarget.value });
                break;
            case 'password':
                this.setState({ password: element.currentTarget.value });
                break;
            case 'confirm_password':
                this.setState({ confirm_password: element.currentTarget.value });
                break;
        }
    }

    onSelectHandler = ( value: string ): void => {
        this.setState({ role: value });
    }

    onSubmit = ( e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if ( this.state.password !== this.state.confirm_password ) {
            this.setState({ 
                message: {
                    type: "warning",
                    content: "Passwords do not match"
                }
            });
            return;
        }

        let elem = this;
        
        userService.createUser(this.props.user, {
            username: this.state.username,
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            role: this.state.role,
            password: this.state.password,
        }).then((res) => {
            
            this.props.setShowLoading(false);
            
            if ( res && res.id ) {
                elem.setState({
					message: {
						type: 'success',
						content: 'User successfully created'
					}
				});

                elem.props.onCreate( res );
            } else if (res && res.message ) {
                elem.setState({
					message: {
						type: 'warning',
						content: res.message
					}
				});
            } else {
                console.log(res);
                elem.setState({
					message: {
						type: 'warning',
						content: 'An unknown error occured. Please check your console.'
					}
				});
            }
        }).catch( (exception) => {

            this.props.setShowLoading(false);

            console.log(exception);
            elem.setState({
                message: {
                    type: 'warning',
                    content: 'An unknown error occured. Please check your console.'
                }
            });
        });
    }

    render() {

        let message = <div></div>

        if ( this.state.message ) {
            message = <Message messageType={this.state.message.type} content={this.state.message.content} />
        }

        return(
            <div className="addUserForm">
                {message}
                <form onSubmit={this.onSubmit} >
                    <LoadingOverlay visible={this.props.showLoading} overlayBlur={2} />
                    <SimpleGrid cols={2}>
                        <TextInput
                            required
                            placeholder="Max"
                            label="First name"
                            name='first_name'
                            onChange={this.onChangeHandler}
                        />
                        <TextInput
                            required
                            placeholder="Muster"
                            label="Last name"
                            name='last_name'
                            onChange={this.onChangeHandler}
                        />
                    </SimpleGrid>
                    <SimpleGrid cols={2}>

                        <TextInput
                            required
                            placeholder="maxmuster"
                            label="Username"
                            name="username"
                            onChange={this.onChangeHandler}
                        />

                        <TextInput
                            required
                            placeholder="max@muster.com"
                            label="Email"
                            name="email"
                            onChange={this.onChangeHandler}
                        />

                    </SimpleGrid>
                    <Select
                        required
                        label="Role / Capabilites"
                        placeholder="Pick a role"
                        onChange={this.onSelectHandler}
                        data={[
                            { value: 'customer', label: 'Customer' },
                            { value: 'editor', label: 'Editor' },
                            { value: 'admin', label: 'Administrator' },
                        ]}
                        />
                    <SimpleGrid cols={2}>
                    <PasswordInput
                        required
                        placeholder="Password"
                        label="Password"
                        name='password'
                        onChange={this.onChangeHandler}
                    />
                    <PasswordInput
                        required
                        placeholder="Password"
                        label="Confirm"
                        name="confirm_password"
                        onChange={this.onChangeHandler}
                    />
                    </SimpleGrid>
                    <div className='clear' />
                    <div className='seperator25 whitespace' />
                    <button title='Create User' className='mainButton' type='submit'><FontAwesomeIcon icon={faUserCheck} /> Create User</button>
                </form>
            </div>
        )
    }
}

export default AddUserForm;