import axios from 'axios'
import config from '../config';


const login = async (credentials: any) => {
  const response = await axios.post(config.api.url + '/auth/login', credentials)
  return response.data
}


const loginService = {
	login,
}

export default loginService;
