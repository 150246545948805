import React, { Component } from 'react'
import clientService from '../../../services/clientService'
import Message from '../message'

/**
 * Declare CreateProject Props
 */
type CreateClientFormProps = {
    user: LoggedUser,
    onCreate: (Client: Client) => void
}

/**
 * Declare AccountForm State
 */
type CreateClientFormState = {
    client_name: string,
    client_logo: Blob | string,
    message: {
        type: string,
        content: string,
    } | null
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class CreateClientForm extends Component<CreateClientFormProps, CreateClientFormState> {


    /**
     * Constructor
     * Initiante the AccountForm Component Class
     * 
     * @param props The CreateClientFormProps
     */
    constructor(props: CreateClientFormProps) {
        super(props)

        this.state = {
            client_name: "",
            client_logo: "",
            message: null
        }
    }

    
    /**
     * Handle user update.
     * This function is called when the user hits the submit button. 
     * Calls the backend API with the provided data and updates it
     * 
     * @param form The HTML Form Element
     */
    onSubmit = async ( form: React.FormEvent<HTMLFormElement> ) => {

        form.preventDefault()
           
        // define data to update
        let formData = new FormData();
        formData.append('client_name', this.state.client_name );
        formData.append('client_logo', this.state.client_logo );

        let elem = this

        clientService.createClient(this.props.user, formData).then(function(res: any) {
            if ( res && res.id ) {
                elem.setState({
                    message: {
                        type: "success",
                        content: "Client successfully created"
                    }
                });

                elem.props.onCreate( res );
            } else if ( res && res.message ) {
                elem.setState({
                    message: {
                        type: "warning",
                        content: res.message
                    }
                });
                
            } else {

                elem.setState({
                    message: {
                        type: "warning",
                        content: "An unknown error occured."
                    }
                });
            }
        });
    }


    /**
     * Handle form input changes
     * Updates the corresponding values and store the in the class's state
     * 
     * @param element The HTML Input Element
     */
    onChangeHandler = ( element: React.FormEvent<HTMLInputElement> ): void  => {

        this.setState({ client_name: element.currentTarget.value });
    }

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if ( e.currentTarget.files != null ) {
            this.setState({ client_logo: e.currentTarget.files[0] });
        }
    }


    /**
     * Render the element
     * 
     * @returns The rendered AccountForm HTML
     */
    render() {

        let button = <div></div>;

        //  Determine if we should render a message
        if ( this.state.message ) {
            button = <Message messageType={this.state.message.type} content={this.state.message.content} />
        } 

        return(
            <div className="form-wrapper">
                {button}
                <form onSubmit={this.onSubmit}>
                    <label>Client Name</label>
                    <div className="input-wrapper ClientNameField">
                        <input 
                            title='Client Name'
                            placeholder='My new Client'
                            id="client_name"
                            name="client_name"
                            value={this.state.client_name}
                            onChange={this.onChangeHandler}
                        ></input>
                    </div>

                    <label>Logo</label>
                    <div className="input-wrapper dataUploadField">
                    <input
                        title="Client Files"
                        accept="image/svg+xml"
                        id="client_logo"
                        name="client_logo"
                        type="file"
                        multiple={false}
                        onChange={this.handleFileChange}
                        />
                    </div>
                <div className="whitespace seperator25"></div>
                <button className="mainButton" type="submit">Create Client</button>
            </form>
            <div className="whitespace seperator150"></div>
            </div>
        )
    }
}

export default CreateClientForm