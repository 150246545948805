import axios from 'axios'
import config from '../config';

const fetchProjects = async (user: LoggedUser) => {
    if ( ! user ) {
        return null;
    }

    try {
        const request =  axios({
            "method": "GET",
            "url": config.api.url + "/api/translation",
            "headers": {
                "Authorization": user.token,
                "Content-Type": "application/json; charset=utf-8"
            }
        })
    
        const response = await request;
        return response.data;
    } catch(exception) {
        console.log(exception)
        return false
    }
}

const downloadXLSX = async ( user: LoggedUser, project: TranslationProject ) => {

    if ( ! user || ! project ) {
        return null;
    }

    try {
        const request = axios({
          "method": "POST",
          "url": config.api.url + "/api/translation/" + project.id + "/download-xlsx",
          "responseType": "blob",
          "headers": {
            "Authorization": user.token,
          },
        });
      
        const response = await request;
        return window.URL.createObjectURL(new Blob([response.data]));
      } catch( exception ) {
          console.log( exception );
          return false;
      }
}


const downloadXLIFF = async ( user: LoggedUser, project: TranslationProject ) => {

    if ( ! user || ! project ) {
        return null;
    }

    try {
        const request = axios({
          "method": "POST",
          "url": config.api.url + "/api/translation/" + project.id + "/download-xliff",
          "responseType": "blob",
          "headers": {
            "Authorization": user.token,
          },
        });
      
        const response = await request;
        return window.URL.createObjectURL(new Blob([response.data]));
      } catch( exception ) {
          console.log( exception );
          return false;
      }
}

const importXLSX = async ( user: LoggedUser, project: TranslationProject, formData: any ) => {
    if ( ! user ) {
        return null;
    }

    try {
        const request = axios({
          "method": "POST",
          "url": config.api.url + "/api/translation/" + project.id + "/upload-xlsx",
          "headers": {
            "Authorization": user.token,
            "Content-Type": "application/json; charset=utf-8"
          },
          "data": formData,
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return exception;
      }
}

const fetchProjectDetail = async (object_id: string, user: LoggedUser) => {
    if ( ! user ) {
        return null;
    }

    try {
        const request =  axios({
            "method": "GET",
            "url": config.api.url + "/api/translation/" + object_id,
            "headers": {
                "Authorization": user.token,
                "Content-Type": "application/json; charset=utf-8"
            }
        })
    
        const response = await request;
        return response.data;
    } catch(exception) {
        console.log(exception)
        return false
    }
}

const createProject = async ( user: LoggedUser, formData: any ) => {

    if (! user || ! user.token ) {
        return new Error( "User not present!" );
      }
    
    try {
      const request = axios({
        "method": "POST",
        "url": config.api.url + "/api/translation",
        "headers": {
          "Authorization": user.token,
          "Content-Type": "application/json; charset=utf-8"
        },
        "data": formData,
      });
    
      const response = await request;
      return response.data;
    } catch( exception ) {
        console.log( exception );
        return exception;
    }

}

/**
 * Update user data
 * 
 * @param {object} user
 * @returns The udpated user
 */
 const updateProject = async (user: LoggedUser, projectUpdateData: {
    project_id: string,
    project_name?: string,
    project_status?: string,
 }) => {

	if ( ! projectUpdateData || ! user ) {
		return null;
	}
    
    
    try {
        const request = axios({
            "method": "PUT",
            "url": config.api.url + "/api/translation/" + projectUpdateData.project_id,
            "headers": {
                "Authorization": user.token,
                "Content-Type": "application/json; charset=utf-8"
            },
            "data": projectUpdateData
        });

        const response = await request;
        return response.data;
    } catch( exception ) {
        console.log( exception );
        return false;
    }
}

const addJob = async ( user: LoggedUser, project: TranslationProject, data: any) => {

    if (! user || ! user.token ) {
        return new Error( "User not present!" );
      }
    
    try {
      const request = axios({
        "method": "POST",
        "url": config.api.url + "/api/translation/" + project.id + "/job",
        "headers": {
          "Authorization": user.token,
          "Content-Type": "application/json; charset=utf-8"
        },
        "data": data,
      });
    
      const response = await request;
      return response.data;
    } catch( exception ) {
        console.log( exception );
        return exception;
    }

}


const deleteProject = async ( user: LoggedUser, project_id: string) => {

  if (! user || ! user.token ) {
      return new Error( "User not present!" );
    }
  
  try {
    const request = axios({
      "method": "DELETE",
      "url": config.api.url + "/api/translation/" + project_id,
      "headers": {
        "Authorization": user.token,
        "Content-Type": "application/json; charset=utf-8"
      },
    });
  
    const response = await request;
    return response.data;
  } catch( exception ) {
      console.log( exception );
      return exception;
  }

}

const translationService = {
  fetchProjects,
  createProject,
  fetchProjectDetail,
  updateProject,
  deleteProject,
  importXLSX,
  downloadXLSX,
  downloadXLIFF,
  addJob,
}

export default translationService;
