import React, { Component } from 'react';
import './../../public/css/login.css';
import { NavLink } from 'react-router-dom';


/**
 * Define Login Prop types
 */
type PasswordResetProps = {
    email: string,
}


/**
 * The Login Page Class
 */
class PasswordReset extends Component<PasswordResetProps> {

    /**
     * Handle the password reset
     * @param form The password-reset HTMLFormElement 
     */
    onSubmit: React.FormEventHandler<HTMLFormElement> = (form) => {
        
        alert('Password reset attempted!')
        // TODO: Send PW reset email
    };

	/**
	 * Render the lost-password Page HHTML
	 * 
	 * @returns the rendered HTML for the password-reset page
	 */
	render() {
			
        // Return the password-reset form
        return (
            <div className="wrapper">
                <div className="box">
                <div className="loginTitle">
                    <span>Reset your password</span>
                </div>
                <span className="info-text">We will send an email to your account with instructions on how to reset your password</span>
                <div className="box-row">
                    <div className="box-right">
                    <form onSubmit={this.onSubmit}>
                        <label>E-mail</label>
                        <div className="input-wrapper">
                            <input
                            title='Email'
                            type="text"
                            value={this.props.email}
                            name="Email"
                            key='email'
                            />
                        </div>
                        <button className="submit-btn" type="submit">Send Instructions</button>
                    </form>
                    <div className="legal">
                            <NavLink to="/login/"><span className="signIn">Back to Login</span></NavLink>
                        </div>
						<div className="legal">
                            <a href="https://inventry.app" target="_blank" rel="noopener noreferrer">Inventry</a>
							<span className="seperator">|</span>
							<a href="https://inventry.app" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
							<span className="seperator">|</span>
							<a href="https://inventry.app/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy</a>
						</div>
                    </div>
                </div>
                </div>
            </div>
            )
	  }
}

  
  
export default PasswordReset;