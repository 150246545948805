import { Component } from 'react'
import { Accordion, Text } from '@mantine/core';
import TranslateProject from '../../popups/TranslateProject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import projectService from '../../../services/translationService';
import ImportXLSX from '../../popups/ImportXLSX';

/**
 * Declare CreateProject Props
 */
type ManageProjectProps = {
    user: LoggedUser,
    project: TranslationProject,
    onUpdateHandler: ( project: TranslationProject ) => void
}


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class ProjectManagement extends Component<ManageProjectProps> {

    onDownloadXLSXClick = () => {

        let elem = this;

        projectService.downloadXLSX( this.props.user, this.props.project ).then(function(url: any) {
			if ( url ) {

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', elem.props.project.name + '-xlsx.zip');
                document.body.appendChild(link);
                link.click();

                projectService.updateProject( elem.props.user, {
                    project_id: elem.props.project.id,
                    project_status: "Translations_In_Correction",
                } ).then(( res ) => {

                    if ( res && res.id ) {
                        // success
                        elem.props.onUpdateHandler( res );
                    } else {
                        alert( res );
                    }
                    
                });
            } else {
                alert( "Failed to download XLSX for this project. Please check the logs" )
            }
		}).catch(function(e) {
            console.log( e );
            alert( "Failed to make request for XLSX files. Please check your console for more info." );
        })
    }

    onDownloadXLIFFClick = () => {

        let elem = this;

        projectService.downloadXLIFF( this.props.user, this.props.project ).then(function(url: any) {
			if ( url ) {

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', elem.props.project.name + '-xliff.zip');
                document.body.appendChild(link);
                link.click();

                projectService.updateProject( elem.props.user, {
                    project_id: elem.props.project.id,
                    project_status: "Completed",
                } ).then(( res ) => {

                    if ( res && res.id ) {
                        // success
                        elem.props.onUpdateHandler( res );
                    } else {
                        alert( "Failed to update status: " + res );
                    }
                    
                });
            } else {
                alert( "Failed to download XLIFF files for this project. Please check the logs" )
            }
		}).catch(function(e) {
            console.log( e );
            alert( "Failed to make request for XLIFF files. Please check your console for more info." );
        })
    }

    render() {
        return(
            <>
            <div className='seperator25 whitespace'></div>
            <Accordion variant="separated" defaultValue={this.props.project.status}>
                <Accordion.Item value="Created">
                    <Accordion.Control>Pre-Translate Project with DeepL</Accordion.Control>
                    <Accordion.Panel>
                        <p>Translate each job seperately with the DeepL API. During translation, please do not close this window.</p>
                        <TranslateProject user={this.props.user} project={this.props.project} onUpdate={this.props.onUpdateHandler} />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Pretranslated">
                    <Accordion.Control>Export XLSX Files</Accordion.Control>
                    <Accordion.Panel>
                        <p>Download the XLSX files to be sent to the customer for control and correction.</p>
                        <button type="button" onClick={this.onDownloadXLSXClick} className="mainButton btn btn-secondary"><FontAwesomeIcon icon={faFileExcel} />Export XLSX</button>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Translations_In_Correction">
                    <Accordion.Control>Import corrected XLSX files</Accordion.Control>
                    <Accordion.Panel>
                        <p>Upload the corrected XLSX files you received from the customer. Make sure to zip all files in a single archive, without any directories!</p>
                        <ImportXLSX user={this.props.user} project={this.props.project} onUpdate={this.props.onUpdateHandler} />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Translations_Corrected">
                    <Accordion.Control>Export XLIFF for WPML</Accordion.Control>
                    <Accordion.Panel>
                        <p>Export the XLIFF files containing the final translation. Upload the archive you downloaded to WPML to complete the translations on the website.</p>
                        <button type="button" onClick={this.onDownloadXLIFFClick} className="mainButton btn btn-secondary"><FontAwesomeIcon icon={faFileArrowDown} />Export XLIFF</button>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Completed">
                    <Accordion.Control>Support & Assistance</Accordion.Control>
                    <Accordion.Panel><Text>If you need any assistance with translating a project, please refer to the <a href="https://wiki.cooper-media.dev/index.php/Translation" target="_blank" rel="noreferrer noopener">Wiki-Article on website translation</a>.</Text></Accordion.Panel>
                </Accordion.Item>

                </Accordion>
                <div className='seperator25 whitespace'></div>
                </>
        );
    }
}

export default ProjectManagement