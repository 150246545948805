import axios from 'axios'
import config from '../config';

const fetchJobs = async (user: LoggedUser, project: TranslationProject) => {
    if ( ! user ) {
        return null;
    }

    try {
        const request =  axios({
            "method": "GET",
            "url": config.api.url + "/api/job",
            "headers": {
                "Authorization": user.token,
                "Content-Type": "application/json; charset=utf-8"
            }
        })
    
        const response = await request;
        return response.data;
    } catch(exception) {
        console.log(exception)
        return false
    }
}

const translateJob = async ( user: LoggedUser, job: TranslationJob ) => {

    if ( ! user || ! job ) {
        return null;
    }

    try {
        const request = axios({
          "method": "POST",
          "url": config.api.url + "/api/job/" + job.id + "/translate",
          "headers": {
            "Authorization": user.token,
          },
        });
      
        const response = await request;
        return response.data;
      } catch( exception ) {
          console.log( exception );
          return false;
      }
}



const fetchJobDetails = async (object_id: string, user: LoggedUser) => {
    if ( ! user ) {
        return null;
    }

    try {
        const request =  axios({
            "method": "GET",
            "url": config.api.url + "/api/job/" + object_id,
            "headers": {
                "Authorization": user.token,
                "Content-Type": "application/json; charset=utf-8"
            }
        })
    
        const response = await request;
        return response.data;
    } catch(exception) {
        console.log(exception)
        return false
    }
}

const addJob = async ( user: LoggedUser, project: TranslationProject, formData: any ) => {

    if (! user || ! user.token ) {
        return new Error( "User not present!" );
      }
    
    try {
      const request = axios({
        "method": "POST",
        "url": config.api.url + "/api/job",
        "headers": {
          "Authorization": user.token,
          "Content-Type": "application/json; charset=utf-8"
        },
        "data": formData,
      });
    
      const response = await request;
      return response.data;
    } catch( exception ) {
        console.log( exception );
        return exception;
    }

}


const updateJob = async ( user: LoggedUser, job: {
    id: string,
}, ) => {

    if (! user || ! user.token ) {
        return new Error( "User not present!" );
      }
    
    try {
      const request = axios({
        "method": "PUT",
        "url": config.api.url + "/api/job/" + job.id,
        "headers": {
          "Authorization": user.token,
          "Content-Type": "application/json; charset=utf-8"
        },
        "data": job,
      });
    
      const response = await request;
      return response.data;
    } catch( exception ) {
        console.log( exception );
        return exception;
    }

}


const deleteJob = async ( user: LoggedUser, job_id: string ) => {

  if (! user || ! user.token ) {
      return new Error( "User not present!" );
    }
  
  try {
    const request = axios({
      "method": "DELETE",
      "url": config.api.url + "/api/job/" + job_id,
      "headers": {
        "Authorization": user.token,
        "Content-Type": "application/json; charset=utf-8"
      },
    });
  
    const response = await request;
    return response.data;
  } catch( exception ) {
      console.log( exception );
      return exception;
  }

}

const jobService = {
	fetchJobs,
	addJob,
	fetchJobDetails,
	updateJob,
	deleteJob,
	translateJob,
}

export default jobService;
