import React, { Component, useEffect } from 'react'
import { Table } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { getUsers } from '../../../reducers/userReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import userService from '../../../services/userService';
/**
 * Declare CreateProject Props
 */
type UsersTableProps = {
    onDelete: (username: string) => void,
    onNavigate: (url: string) => void,
    users: TranslationUser[],
    loggedUser: LoggedUser,
}

const UsersTableWrapper = ( props: { user: LoggedUser }) => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    
    const usersDispatch = useAppDispatch();
    useEffect(() => {
        usersDispatch(getUsers(props.user))
    },[usersDispatch, props.user]);

    const users = useAppSelector( ({users}) => {
        return users;
    });
    
    const onNavigate = (url: string) => {
        navigate( url );
    };

    const onDelete = (username: string) => {
        dispatch({
            type: 'DELETE_USER',
            data: username,
        });
    };

    return(
        <UsersTable users={users} onNavigate={onNavigate} onDelete={onDelete} loggedUser={props.user}/>
    )
}

/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
class UsersTable extends Component<UsersTableProps> {

    onLearnMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button = event.currentTarget;

        this.props.onNavigate( "/admin/user/" + button.name );
    }

    onDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button = event.currentTarget;
        let elem = this;

        if ( this.props.loggedUser.user_object.username === button.name ) {
            alert( "WARNING: You are trying to delete the user you are logged in with. That is not possible.");
            return;
        }

        userService.deleteUser(this.props.loggedUser, button.name).then((res) => {

            if ( ! res.message ) {
                // Success
                elem.props.onDelete( button.name );
            } else {
                alert( res );
            }
        }).catch( (exception) => {
            console.log( exception );
            alert(exception);
        });
    }

    render() {

        if ( this.props.users ) {
            return(
                <Table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{this.props.users.map((user) => (
                        <tr key={user.email}>
                            <td>{user.first_name + " " + user.last_name}</td>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</td>
                            <td className='actionTD'>
                            { /* <button title="Learn more" name={user.username} onClick={this.onLearnMore} className="btn altButton moreButton"><FontAwesomeIcon icon={faEllipsisH} /></button> */ }
                                <button title="Delete Job" name={user.username} onClick={this.onDelete} className="btn mainButton deleteButton"><FontAwesomeIcon icon={faCircleXmark} /></button>
                            </td>
                      </tr>
                    ))}</tbody>
                    </Table>
            );
        } else {
            return (
                <p>No users found.</p>
            );
        }
        
    }
}

export default UsersTableWrapper