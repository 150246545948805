import { Paper, Group, Text, createStyles, SimpleGrid } from '@mantine/core';
import { TablerIcon, IconLanguage, IconUsers, IconColorSwatch } from '@tabler/icons';

/**
 * Declare CreateProject Props
 */
type DashboardStatsProps = {
    clients: Client[],
    translationProjects: TranslationProject[]
    rebrandingProjects: RebrandingProject[]
}

interface StatsGridIconsProps {
    title: string,
    value: number,
	icon: TablerIcon,
  }

const useStyles = createStyles((theme) => ({
    label: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
  }));


/**
 * AccountForm Component
 * Allows the user to view and / or edit his account details
 * 
 */
const DashboardStats = (props: DashboardStatsProps) => {
    
	const data = [
        { title: "Clients", value: props.clients ? props.clients.length : 0, icon: IconUsers },
        { title: "Translation Projects", value: props.translationProjects ? props.translationProjects.length : 0, icon: IconLanguage },
        { title: "Rebranding Projects", value: props.rebrandingProjects ? props.rebrandingProjects.length : 0, icon: IconColorSwatch },
    ]
	
	return(
        <div>
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {data.map( ( stat ) => (
                    <StatsCard {...stat} key={stat.title} />
                ))}
            </SimpleGrid>
        </div>
    )
    
}

export default DashboardStats

const StatsCard = (props: StatsGridIconsProps) => {
    
    const { classes } = useStyles();

    return(
        <Paper withBorder p="md" radius="md" key={props.title}>
        <Group position="apart">
          <div>
            <Text
              color="dimmed"
              transform="uppercase"
              weight={700}
              size="xs"
              className={classes.label}
            >
              {props.title}
            </Text>
            <Text weight={700} size="xl">
              {props.value}
            </Text>
          </div>
		  <props.icon stroke={1.5} />
        </Group>
      </Paper>
    )
}