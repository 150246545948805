import { faGears, faInfoCircle, faUserCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Grid, Modal } from '@mantine/core';
import { Component, Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import AddUserForm from './add-user-form';
import UsersTable from './users-table';


/**
 * Type definition for Wrapper Properties
 */
type WrapperProps = {
    user: LoggedUser|null
}


/**
 * Type Definition for AdminHome properties
 */
 type AdminAreaHomeProps = {
    admin: LoggedUser,
    setOpenedAddUser: Dispatch<SetStateAction<boolean>>
}


/**
 * Type Definition for AdminHome state
 */
type AdminAreaHomeState = {
    
}


/**
 * Wrapper for the AdminArea.
 * Wraps around the admin area components to allow usage of react hooks
 * 
 * @param props WrapperProps: Contains the current user
 * @returns The AdminArea HTML
 */
const AdminAreaWrapper = (props: WrapperProps) => {

    let dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false); 

    const [openedAddUser, setOpenedAddUser] = useState(false);
    
    const onCreateUser = ( user: TranslationUser ) => {
        if ( user ) {
            dispatch({
                type: "ADD_USER",
                data: user,
            });
        }
    }

    if ( ! props.user ) {
        return <Navigate to="/login" />
    }

    if ( props.user && props.user.user_object.role === 'admin' ) {
        return(
            <>
                <Modal
                    opened={openedAddUser}
                    onClose={() => {setOpenedAddUser(false)}}
                    title="Add new user"
                >
                    <AddUserForm user={props.user} onCreate={onCreateUser} showLoading={showLoading} setShowLoading={setShowLoading} />
                </Modal>
                <AdminAreaHome admin={props.user} setOpenedAddUser={setOpenedAddUser} />
            </>
        )
    } else {
        return (
            <div className="adminNotAuthorized">
                <p>You are not authorized to be here...</p>
            </div>
        )
    }
}

/**
 * The AdminArea Home Compoent
 * Renders the entry page to the admin area
 */
class AdminAreaHome extends Component<AdminAreaHomeProps, AdminAreaHomeState> {

    /**
     * Renders the component
     * 
     * @returns The rendered HTML for the admin area homepage
     */
    render() {
        return(
            <div className="adminWrapper">
                <Grid justify="space-between" align="center">
                    <Grid.Col span={2} style={{ minHeight: 80, minWidth: 900 }}><h1>Administration</h1></Grid.Col>
                </Grid>
                <Tabs defaultValue="users">
                <Tabs.List>
                    <Tabs.Tab value="users"><FontAwesomeIcon icon={faUserCircle} /> User Management</Tabs.Tab>
                    <Tabs.Tab value="settings"><FontAwesomeIcon icon={faGears} /> Settings</Tabs.Tab>
                    <Tabs.Tab value="info"><FontAwesomeIcon icon={faInfoCircle} /> Build Info</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="users" pt="xs">
                    <button className='button mainButton addUserButton' onClick={() => {this.props.setOpenedAddUser(true)}}><FontAwesomeIcon icon={faUserPlus} /> Add User</button>
                    <div className='seperator15 whitespace'></div>
                    <UsersTable user={this.props.admin} />
                </Tabs.Panel>

                <Tabs.Panel value="settings" pt="xs">
                    <div className='seperator15 whitespace'></div>
                    <p>Settings go here</p>
                </Tabs.Panel>

                <Tabs.Panel value="info" pt="xs">
                    <div className='seperator15 whitespace'></div>
                    <p>Build info goes here...</p>
                </Tabs.Panel>
            </Tabs>
            </div>
        )
    }
}

export default AdminAreaWrapper;